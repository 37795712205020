import { useAuth } from "@/context/UserProvider";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const AuthRoute = ({ Component, ...rest }) => {
    const { uid } = useAuth()
    const { emailVerified } = useSelector(state => state.Auth)

    
    return (
        <Route
            {...rest}
            render={routeProps =>
                (!!uid && !!emailVerified) ?
                    <Redirect to="/inlenen" /> :
                    <Component {...routeProps} />
            }
        />
    )
}
