const provinces = {
    "Drenthe": {
        color: "magenta",
    },
    "Flevoland": {
        color: "red",
    },
    "Friesland": {
        color: "volcano",
    },
    "Gelderland": {
        color: "orange",
    },
    "Groningen": {
        color: "gold",
    },
    "Limburg": {
        color: "lime",
    },
    "Noord-Brabant": {
        color: "green",
    },
    "Noord-Holland": {
        color: "cyan",
    },
    "Overijssel": {
        color: "blue",
    },
    "Utrecht": {
        color: "geekblue",
    },
    "Zeeland": {
        color: "purple",
    },
    "Zuid-Holland": {
        color: "magenta",
    },
}

export default provinces