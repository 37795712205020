import React from 'react'
import styled from 'styled-components';
import { DashboardContent } from '@/containers/DashboardLayout/DashboardContent'
import { DashboardHeader } from '@/containers/DashboardLayout/DashboardHeader'
import { DashboardLayout } from '@/containers/DashboardLayout'

const ChatContent = styled.div`
    display: flex;
    width: 100%;
    background: white;
`

export const ChatLayout = ({ children }) => {
    return (
        <DashboardLayout>
            <DashboardHeader>
                <span className="header-title">Chat</span>
            </DashboardHeader>
            <DashboardContent>
                <ChatContent>
                    {children}
                </ChatContent>
            </DashboardContent>
        </DashboardLayout>
    )
}
