import bg from "@/assets/images/bg.jpg";
import { Layout, Row } from '@/components';
import React from 'react';

const { Content } = Layout;

export const FrontPageLayout = ({ children }) => {

    return (
        <Layout style={{ height: '100vh' }}>
            <Layout>
                <Content style={{ backgroundImage: `url(${bg})` }}>
                    <Row justify="space-around" align="middle" style={{ height: "100%" }}>
                        {children}
                    </Row>
                </Content>
            </Layout>
        </Layout>
    )

}