import React from 'react'
import { Button, Col, Row } from '@/components'
import { LeftOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"

export const ContentHeader = ({ title }) => {
    return (
        <Row justify="space-between" style={{
            flex: '0 0 66px',
            borderBottom: '1px solid rgb(240, 240, 240)',
            alignItems: 'center',
            padding: '0 20px'
        }}>
            <Col xs={12} md={0}>
                <Link to="/chat">
                    <Button icon={<LeftOutlined size={18} />} />
                </Link>
            </Col>
            <span style={{ fontSize: '17px' }}>
                {title}
            </span>
        </Row>
    )
}
