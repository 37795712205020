import React, { useEffect } from 'react'
import employeesActions from '@/store/employees/actions'
import { Col, Spin } from '@/components'
import { Row, SpinWrapper } from '@/pages/styles/workforce.styles'
import { WorkforceSider, WorkforceContent, WorkforceLayout } from "@/containers/Workforce";
import { useDispatch, useSelector } from 'react-redux';
import useDocumentTitle from '@/lib/helpers/appTitle';

const { getEmployees } = employeesActions

export const NewEmployeePage = () => {
    useDocumentTitle('Koppelbaas - Nieuw personeel')
    const dispatch = useDispatch()
    const { loaded } = useSelector(state => state.Employees)

    useEffect(() => {
        if (!loaded) {
            dispatch(getEmployees())
        }
    }, [dispatch, loaded])

    return (
        <WorkforceLayout>
            {!loaded ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <Row>
                    <Col {...({ xs: 0, md: 8, lg: 6 })}>
                        <WorkforceSider />
                    </Col>
                    <Col {...({ xs: 24, md: 16, lg: 18 })}>
                        <WorkforceContent />
                    </Col>
                </Row>}
        </WorkforceLayout >
    )
}