import appActions from '@/store/app/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons'

const { toggleCollapsed } = appActions

const Trigger = () => {
    const dispatch = useDispatch()
    const { collapsed } = useSelector(state => state.App)

    const props = {
        className: 'trigger',
        onClick: () => dispatch(toggleCollapsed()),
    }

    return collapsed ? 
        <MenuUnfoldOutlined {...props} /> : 
        <MenuFoldOutlined {...props} />
}

export { Trigger }