import actions from './actions';

const initState = {
    employees: {},
    fileList: [],
    feedback: {},
    loaded: false,
    loading: true,
    saving: false,
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case actions.DOWNLOAD_URL:
            return {
                ...state,
                fileList: [{
                    uid: 1,
                    status: 'done',
                    url: payload.url
                }]
            }
        case actions.GET_EMPLOYEES:
            return {
                ...state,
            }
        case actions.GET_EMPLOYEES_ERROR:
            return {
                ...state,
                loaded: true,
            }
        case actions.GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                loaded: true,
                employees: payload.employees,
            }
        case actions.REMOVE_FEEDBACK:
            return {
                ...state,
                feedback: {},
            }
        case actions.RESET_FILE_LIST:
            return {
                ...state,
                fileList: initState.fileList
            }
        case actions.SAVE_EMPLOYEE:
            return {
                ...state,
                saving: true,
            }
        case actions.SAVE_EMPLOYEE_ERROR:
            return {
                ...state,
                saving: false,
                feedback: payload.feedback,
            }
        case actions.SAVE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                saving: false,
                feedback: payload.feedback,
            }
        case actions.UPLOAD_START:
            return {
                ...state,
                onUploadStart: true,
                uploadProgress: payload.uploadProgress,
                fileList: [{
                    uid: 1,
                    percent: payload.uploadProgress,
                    status: 'uploading',
                    url: payload.url
                }]
            }
        case actions.UPLOAD_ERROR:
            return {
                ...state,
                fileList: [{
                    uid: 1,
                    status: 'error'
                }]
            }
        default:
            return state;
    }
}
