import { useAuth } from "@/context/UserProvider";
import appActions from "@/store/app/actions";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";

export const PrivateRoute = ({ Component, ...rest }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { uid, emailVerified } = useAuth()
    const { changeCurrent } = appActions

    useEffect(() => {
        dispatch(changeCurrent(window.location.pathname.replace('/', '')))
    }, [dispatch, changeCurrent])

    useEffect(() => {
        return history.listen(({ pathname }) => {
            dispatch(changeCurrent(pathname.replace('/', '')))
        })
    }, [dispatch, changeCurrent, history])

    return (
        <Route
            {...rest}
            render={props =>
                !!uid ?
                    (!!emailVerified ?
                        (
                            <Component {...props} />
                        ) :
                        (
                            <Redirect to={{
                                pathname: '/e-mailadres-bevestigen',
                                state: { from: props.location }
                            }}
                            />
                        )) :
                    (<Redirect to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                    />)
            }
        />
    )
}
