import React, { useEffect } from 'react'
import chatActions from '@/store/chat/actions'
import { ChatContent, ChatLayout, ChatSider } from '@/containers/Chat'
import { Col, Spin } from '@/components'
import { Row, SpinWrapper } from '@/pages/styles/chat.styles'
import { useAuth } from '@/context/UserProvider'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import useDocumentTitle from '@/lib/helpers/appTitle'

const {
    getChatRooms,
    removeChatRoom
} = chatActions

export const ChatRoomPage = ({ match }) => {
    useDocumentTitle('Koppelbaas - Chat')
    const { chatRoom } = match.params
    const dispatch = useDispatch()
    const history = useHistory()
    const { chatRoomsLoaded, chatRooms } = useSelector(state => state.Chat)
    const { uid } = useAuth()

    useEffect(() => {
        if (chatRoom) {
            dispatch(removeChatRoom())
        }
    }, [chatRoom, dispatch])

    useEffect(() => {
        if (!chatRoomsLoaded) {
            dispatch(getChatRooms(uid))
        }
    }, [dispatch, chatRoomsLoaded, uid])

    useEffect(() => {
        if (chatRoomsLoaded  && !chatRooms[chatRoom]) {
            history.push('/chat')
        }
    }, [chatRoom, history, chatRooms, chatRoomsLoaded])

    return (
        <ChatLayout>
            {(!chatRoomsLoaded) ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <Row>
                    <Col {...(chatRoom ? { xs: 0, md: 8, lg: 6 } : { xs: 24, md: 8, lg: 6 })}>
                        <ChatSider />
                    </Col>
                    {chatRooms[chatRoom] &&
                        <Col {...(chatRoom ? { xs: 24, md: 16, lg: 18 } : { xs: 0, md: 16, lg: 18 })}>
                            <ChatContent chatRoom={chatRooms[chatRoom]} />
                        </Col>}
                </Row>}
        </ChatLayout >
    )
}
