import React, { useState } from 'react';
import chatActions from '@/store/chat/actions';
import moment from 'moment';
import { Button } from '@/components';
import { ComposeMessageWrapper, TextArea } from "./styles/ComposeMessage.styles";
import { FileTextOutlined, PhoneOutlined, SendOutlined } from '@ant-design/icons';
import { dateFormat } from '@/lib/helpers/date';
import { useAuth } from '@/context/UserProvider';
import { useDispatch } from 'react-redux';
import CreateContractModal from './CreateContractModal';
import { Grid } from '@/components'

const {
    addMessage,
    toggleContractModal
} = chatActions

const isExpired = ad => {
    return moment(ad.period[1], dateFormat).isBefore(moment(moment().format(dateFormat), dateFormat))
}

export const ComposeMessage = ({ ad, chatRoom }) => {
    const dispatch = useDispatch()
    const screens = Grid.useBreakpoint()
    const { uid } = useAuth()
    const [text, setText] = useState('')

    const handleChange = event => {
        setText(event.target.value)
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            event.preventDefault()
            sendMessage()
        }
    }

    const sendMessage = () => {
        if (text !== "") {
            dispatch(addMessage(chatRoom, { text, uid }))
            setText('')
        }
    }

    const handleSendContract = () => {
        dispatch(toggleContractModal())
    }

    return (
        <ComposeMessageWrapper>
            <TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                disabled={ad && isExpired(ad)}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="Type hier uw bericht"
                value={text}>
            </TextArea>
            <Button
                disabled={ad && isExpired(ad)}
                type="primary" onClick={sendMessage} shape="circle" icon={<SendOutlined style={{ marginLeft: '3px' }} />} style={{ marginRight: '10px' }} />
            <Button
                href={`tel:${chatRoom.hirer.key === uid ? chatRoom.lender.phoneNumber : chatRoom.hirer.phoneNumber}`}
                disabled={ad && isExpired(ad)}
                type="default" shape="circle" 
                icon={<PhoneOutlined />} 
                style={{ marginRight: '10px' }} />
            {((ad.type === 'offered' && chatRoom.hirer.key === uid) || (ad.type === 'offered' && chatRoom.lender.key === uid) || (ad.type === 'wanted' && chatRoom.lender.key === uid )) && !isExpired(ad) &&
                <>
                    <Button type="dark" onClick={handleSendContract} icon={<FileTextOutlined />}>{screens['sm'] && 'Contract versturen'}</Button>
                    <CreateContractModal ad={ad} chatRoom={chatRoom} />
                </>
            }
        </ComposeMessageWrapper >
    );
}
