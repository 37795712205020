import React, { useEffect } from 'react'
import employeesActions from "@/store/employees/actions"
import { Col, Grid, Spin } from '@/components'
import { Row, SpinWrapper } from '@/pages/styles/workforce.styles'
import { WorkforceSider, WorkforceLayout } from "@/containers/Workforce";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router';
import useDocumentTitle from '@/lib/helpers/appTitle';
import styled from 'styled-components';

const {
    getEmployees,
} = employeesActions
export const WorkforcePage = () => {
    useDocumentTitle('Koppelbaas - Personeelsbestand')
    const history = useHistory()
    const dispatch = useDispatch()
    const screens = Grid.useBreakpoint()
    const { employees, loaded } = useSelector(state => state.Employees)

    useEffect(() => {
        if (!loaded) {
            dispatch(getEmployees())
        }
    }, [dispatch, loaded])

    useEffect(() => {
        if (screens['md'] && employees && Object.keys(employees).length > 0) {
            history.push(`/personeelsbestand/${Object.keys(employees)[0]}/bewerken`)
        }
    }, [employees, screens, history])

    const EmptyEmployees = styled.div`
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    `

    return (
        <WorkforceLayout>
            {!loaded ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <Row>
                    <Col {...({ xs: 24, md: 8, lg: 6 })}>
                        <WorkforceSider />
                    </Col>
                    {(!employees || !(Object.keys(employees).length > 0)) &&
                        <Col {...({ xs: 0, md: 16, lg: 18 })}>
                            <EmptyEmployees>
                                <p style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
                                    Oeps, het lijkt erop dat je nog geen personeel hebt,
                                    druk op 'nieuw' en begin met toevoegen.
                                </p>
                            </EmptyEmployees>
                        </Col>
                    }
                </Row>}
        </WorkforceLayout >
    )
}
