const ACTIONS = 'EMPLOYEES_';

const actions = {
    DOWNLOAD_URL: ACTIONS + 'DOWNLOAD_URL',
    GET_EMPLOYEES: ACTIONS + 'GET_EMPLOYEES',
    GET_EMPLOYEES_ERROR: ACTIONS + 'GET_EMPLOYEES_ERROR',
    GET_EMPLOYEES_SUCCESS: ACTIONS + 'GET_EMPLOYEES_SUCCESS',
    REMOVE_FEEDBACK: ACTIONS + 'REMOVE_FEEDBACK',
    RESET_FILE_LIST: ACTIONS + 'RESET_FILE_LIST',
    SAVE_EMPLOYEE: ACTIONS + 'SAVE_EMPLOYEE',
    SAVE_EMPLOYEE_ERROR: ACTIONS + 'SAVE_EMPLOYEE_ERROR',
    SAVE_EMPLOYEE_SUCCESS: ACTIONS + 'SAVE_EMPLOYEE_SUCCESS',
    REMOVE_EMPLOYEE: ACTIONS + 'DELETE_EMPLOYEE',
    REMOVE_EMPLOYEE_ERROR: ACTIONS + 'DELETE_EMPLOYEE_ERROR',
    REMOVE_EMPLOYEE_SUCCESS: ACTIONS + 'DELETE_EMPLOYEE_SUCCESS',
    UPLOAD: ACTIONS + 'UPLOAD',
    UPLOAD_ERROR: ACTIONS + 'UPLOAD_ERROR',
    UPLOAD_START: ACTIONS + 'UPLOAD_START',

    downloadURL: url => ({ type: actions.DOWNLOAD_URL, payload: { url } }),
    getEmployees: () => ({ type: actions.GET_EMPLOYEES, }),
    getEmployeesError: error => ({ type: actions.GET_EMPLOYEES_ERROR, payload: { error } }),
    getEmployeesSuccess: employees => ({ type: actions.GET_EMPLOYEES_SUCCESS, payload: { employees }, }),
    removeFeedback: () => ({ type: actions.REMOVE_FEEDBACK }),
    resetFileList: () => ({ type: actions.RESET_FILE_LIST }),
    saveEmployee: (employee, data, profilePicture) => ({ type: actions.SAVE_EMPLOYEE, payload: { employee, data, profilePicture }, }),
    saveEmployeeError: feedback => ({ type: actions.SAVE_EMPLOYEE_ERROR, payload: { feedback } }),
    saveEmployeeSuccess: feedback => ({ type: actions.SAVE_EMPLOYEE_SUCCESS, payload: { feedback } }),
    removeEmployee: (contractor, employee) => ({ type: actions.REMOVE_EMPLOYEE, payload: { contractor, employee }, }),
    removeEmployeeError: feedback => ({ type: actions.REMOVE_EMPLOYEE_ERROR, payload: { feedback } }),
    removeEmployeeSuccess: feedback => ({ type: actions.REMOVE_EMPLOYEE_SUCCESS, payload: { feedback } }),
    upload: (employee, file) => ({ type: actions.UPLOAD, payload: { employee, file } }),
    uploadStart: uploadProgress => ({ type: actions.UPLOAD_START, payload: { uploadProgress }, }),
    uploadError: error => ({ type: actions.UPLOAD_ERROR, payload: { error }, }),
}
export default actions;
