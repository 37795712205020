import styled from 'styled-components'
import { palette } from 'styled-theme'

const ChatMessagesWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;

  ::-webkit-scrollbar {
    display: none;
  }
`

const SingleMessage = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  align-items: flex-start;
  flex-shrink: 0;

  @media only screen and (max-width: 767px) {
    margin: 10px 0;
  }

  &.loggedUser {
    justify-content: flex-end;
  }
  .messageGravatar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    overflow: hidden;
    margin: 0px 20px;

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .messageContent {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 110px);
    flex-shrink: 0;

    .messageContentText {
      position: relative;
      font-size: 14px;
      vertical-align: top;
      display: inline-block;
      padding: 10px 15px;
      word-break: break-word;

      p {
        margin: 0;
      }
    }
    .messageTime {
      font-size: 12px;
      margin-top: 5px;
    }
    &.isUser {
      align-items: flex-end;
      .messageContentText {
        border-radius: 3px 0 3px 3px;
        background: ${palette('primary', 0)};

        &:after {
          content: '';
          position: absolute;
          border-style: solid;
          display: block;
          width: 0;
          top: 0;
          bottom: auto;
          left: auto;
          right: -9px;
          border-width: 0px 0 10px 10px;
          border-color: transparent ${palette('primary', 0)};
          margin-top: 0;
        }
      }
      .messageTime {
        margin-left: auto;
      }
    }
    &.notUser {
      align-items: flex-start;

      .messageContentText {
        border-radius: 0 3px 3px 3px;
        background: lightgray;

        &:after {
          content: '';
          position: absolute;
          border-style: solid;
          display: block;
          width: 0;
          top: 0;
          bottom: auto;
          left: -9px;
          border-width: 0px 10px 10px 0;
          border-color: transparent lightgray;
          margin-top: 0;
        }
      }
      .messageTime {
        margin-right: auto;
      }
    }
  }
`;

export {
  ChatMessagesWrapper,
  SingleMessage
}