import actions from './actions'
import firebase, { auth, db, storage } from '@/lib/firebase'
import { buffers, eventChannel, END } from 'redux-saga'
import { all, put, take, takeEvery, call } from 'redux-saga/effects'

const collectionPath = 'employees'

function* getEmployees() {

    const ref = db
        .collection('contractors')
        .doc(auth.currentUser.uid)
        .collection(collectionPath)
        .where('deletedAt', '==', null)
       
    const channel = eventChannel(emit => ref.onSnapshot(emit, _ => emit(END)))
    try {
        while (true) {
            const employeesSnapshot = yield take(channel)

            const employees = employeesSnapshot.docs.reduce((employees, doc) => {
                employees[doc.id] = { id: doc.id, ...doc.data() }
                return employees
            }, {})

            yield put(actions.getEmployeesSuccess(employees))
        }
    } catch (error) {
        yield put(actions.getEmployeesError(error))
    }
}

function* saveEmployee({ payload }) {
    const { employee, data, profilePicture } = payload
    data['uid'] = auth.currentUser.uid
    data['updatedAt'] = new Date().getTime()
    var employeeId = 0
    try {
        if (employee) {
            // Update
            const ref = yield db.collection('contractors').doc(auth.currentUser.uid).collection('employees').doc(employee)
            employeeId = ref.id
            yield ref.set(data, { merge: true })
        } else {
            // Insert
            const ref = yield db.collection('contractors').doc(auth.currentUser.uid).collection('employees').doc()
            data['createdAt'] = data['updatedAt']
            data['deletedAt'] = null
            employeeId = ref.id
            yield ref.set(data)
            yield db.collection('contractors').doc(auth.currentUser.uid).collection('employees').doc(employeeId).update({ key: employeeId })
        }
        if (profilePicture) {
            yield call(upload, {payload: {employee: employeeId, file: profilePicture}})
        }
        
        yield put(actions.saveEmployeeSuccess({ type: 'success', content: `${employee ? 'Wijzigen' : 'Opslaan'} gelukt`, savedId: employeeId }))
    } catch (error) {
        yield put(actions.saveEmployeeError({ type: 'error', content: 'Er ging iets mis' }))
    }
}

function* removeEmployee({ payload }) {
    const { contractor, employee } = payload
    try {
        const ref = yield db.collection('contractors').doc(contractor).collection('employees').doc(employee)
        yield ref.set({ deletedAt: new Date().getTime(), }, { merge: true })
        yield put(actions.removeEmployeeSuccess({ type: 'success', content: `Verwijderen gelukt` }))
    } catch (error) {
        console.log(error)
        yield put(actions.removeEmployeeError({ type: 'error', content: 'Er ging iets mis' }))
    }
}

function* upload({ payload }) {
    const { employee, file } = payload

    const ref = storage
        .ref(`users/${auth.currentUser.uid}/employees/${employee}`)
        .child(file.name)

    const task = ref.put(file)

    const channel = eventChannel(emit => {

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

                emit({ progress })
            },
            error => {
                emit({ error })
            },
            () => {
                ref
                    .getDownloadURL()
                    .then(url => {
                        emit({ url })
                    })
            }
        )

        return () => {
            task.off("state_changed")
        }

    })

    try {
        while (true) {
            const { progress = 0, error, url } = yield take(channel, buffers.sliding(5));

            if (error) {
                yield put(actions.uploadError(error))
                channel.close()
                return
            }

            if (url) {
                const ref = db.collection('contractors').doc(auth.currentUser.uid).collection('employees').doc(employee)
                yield ref.set({ profileImageUrl: url }, { merge: true })
                yield put(actions.downloadURL(url))
                return
            }

            yield put(actions.uploadStart(progress))
        }
    } catch (error) {
        yield put(actions.uploadError(error))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_EMPLOYEES, getEmployees),
        takeEvery(actions.SAVE_EMPLOYEE, saveEmployee),
        takeEvery(actions.UPLOAD, upload),
        takeEvery(actions.REMOVE_EMPLOYEE, removeEmployee),
    ]);
}
