import React from 'react'
import { Layout } from '@/components'

const { Content } = Layout

export const DashboardContent = ({ children }) => {

    return (
        <Content>
            {children}
        </Content>
    )
}
