import React from 'react'
import adsActions from '@/store/ads/actions'
import { FilterTagsWrapper } from './styles/FilterTags.styles'
import { Tag } from '@/components'
import { useTranslation } from "react-i18next";
import { initialValues } from './Filters'
import { dateFormat } from '@/lib/helpers/date';
import { useDispatch, useSelector } from 'react-redux';

const {
    setFilters
} = adsActions
const FilterTags = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const { filters } = useSelector(state => state.Ads)

    const renderTag = filter => {
        if (filter === 'period') {
            return <Tag
                key={filter}
                closable
                onClose={() => dispatch(setFilters({ ...filters, [filter]: initialValues[filter] }))}
                style={{ textTransform: "capitalize" }}>
                {filters[filter][0].format(dateFormat)} - {filters[filter][1].format(dateFormat)}
            </Tag>
        }
        if (Array.isArray(filters[filter])) {
            return filters[filter].map(tag => {
                return <Tag
                    key={tag}
                    closable
                    onClose={() => {
                        const newArray = [...filters[filter]]
                        newArray.splice(filters[filter].indexOf(tag), 1)
                        dispatch(setFilters({ ...filters, [filter]: newArray }))
                    }}
                    style={{ textTransform: "capitalize" }}>
                    {t(tag.toLowerCase())}
                </Tag>
            })
        } else {
            return <Tag
                key={filter}
                closable
                onClose={() => dispatch(setFilters({ ...filters, [filter]: initialValues[filter] }))}
                style={{ textTransform: "capitalize" }}>
                {t(filters[filter])}
            </Tag >
        }
    }

    const filtersActive = filters =>
        Object.keys(filters).filter(filter =>
            (filters[filter] ? true : false) &&
            (Array.isArray(filters[filter]) ? filters[filter].length > 0 : true)
        ).length > 0

    return (
        filtersActive(filters) &&
        <FilterTagsWrapper>
            {Object.keys(filters).map(filter => filters[filter] && renderTag(filter))}
        </FilterTagsWrapper>
    )
}

export default FilterTags