import styled from 'styled-components'

const DetailsFormWrapper = styled.div`
  padding: 0 24px;

  .upload-list-inline .ant-upload-list-item img {
    border-radius: 50%;
  }

  .ant-upload-list-picture .ant-upload-list-item {
    border: none;
    
    .ant-upload-list-item-thumbnail {
      cursor: unset;
  }
}
`

export {
  DetailsFormWrapper,
}
