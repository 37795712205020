import React, { useEffect } from 'react'
import _ from 'lodash'
import employeesActions from "@/store/employees/actions"
import moment from 'moment'
import professions from '@/lib/helpers/professions'
import provinces from '@/lib/helpers/provinces'
import { DatePicker, Divider, Form, Select, Input, InputNumber, Radio } from '@/components'
import { dateFormat } from "@/lib/helpers/date"
import { useDispatch, useSelector } from "react-redux"

const { Option } = Select
const { RangePicker } = DatePicker
const { TextArea } = Input
const { getEmployees } = employeesActions

const OfferedForm = ({ form }) => {
    const dispatch = useDispatch()
    const { ad, ads } = useSelector(state => state.Ads)
    const { employees, loaded } = useSelector(state => state.Employees)

    useEffect(() => {
        if (!loaded) {
            dispatch(getEmployees())
        }
    }, [loaded, dispatch])

    useEffect(() => {
        if (ad) {
            form.setFieldsValue(getAdFields(ads[ad]))
        } else {
            form.resetFields()
        }
    }, [ad, ads, form])

    const handleValuesChange = ({ employee }) => {
        if (employee) {
            form.setFieldsValue(getEmployeeFields(employees[employee]))
        }
    }

    return (
        <Form
            form={form}
            labelAlign="left"
            requiredMark={false}
            colon={false}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onValuesChange={handleValuesChange}>
            <Form.Item
                name="title"
                label="Titel"
                rules={[{ required: true, message: 'Titel is verplicht' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="employee"
                label="Medewerker"
                rules={[{ required: true, message: 'Medewerker is verplicht' }]}>
                <Select
                    loading={!loaded}
                    showSearch
                    notFoundContent={<div />}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {employees && Object.keys(employees).map(employee =>
                        <Option key={employee} value={employee}>{employees[employee].firstName} {employees[employee].lastName}</Option>)
                    }
                </Select>
            </Form.Item>
            <Divider />
            <Form.Item
                name="firstName"
                label="Voornaam">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Achternaam">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="professions"
                label="Functies"
                rules={[{ required: true, message: 'Een functie is verplicht' }]}>
                <Select mode="multiple">
                    {professions.map(profession => <Select.Option key={profession}>{profession}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name="specialty"
                label="Specialiteit"
                rules={[{ required: true, message: 'Een specialiteit is verplicht' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="dateOfBirth"
                label="Geboortedatum">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="province"
                label="Provincie">
                <Select disabled={true}>
                    {Object.keys(provinces).map(province => <Select.Option key={province}>{province}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name="postalCode"
                label="Postcode">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="city"
                label="Plaatsnaam">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="streetName"
                label="Straatnaam">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="houseNumber"
                label="Huisnummer">
                <InputNumber disabled={true} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
                name="addition"
                label="Toevoeging">
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item
                name="description"
                label="Beschrijving"
                rules={[{ required: true, message: 'Een bescrhijving is verplicht' }]}>
                <TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            <Form.Item
                name="vca"
                label="VCA"
                rules={[{ required: true, message: 'VCA is verplicht' }]}>
                <Radio.Group>
                    <Radio value="VCA-vol">VCA-vol</Radio>
                    <Radio value="VCA-basis">VCA-basis</Radio>
                    <Radio value="geen VCA">geen VCA</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="hourlyRate"
                label="Uurtarief"
                rules={[{ required: true, message: 'Een uurtarief is verplicht' }]}>
                <InputNumber
                    decimalSeparator={","}
                    precision={2}
                    formatter={value => `€ ${value}`}
                    min={0}/>
            </Form.Item>
            <Divider />
            <Form.Item
                name="period"
                label="Periode"
                rules={[{ required: true, message: 'Periode is verplicht' }]}>
                <RangePicker
                    placeholder={["Begindatum", "Einddatum"]}
                    format={dateFormat} />
            </Form.Item>
        </Form>
    )
}

const getAdFields = ad => ({
    ..._.pick(ad, ['maxTravelDistance', 'period', 'title']),
    ...getEmployeeFields(ad.employee),
    employee: ad.employee.key,
    period: ad.period.map(date => moment(date, dateFormat))
})

const getEmployeeFields = employee => ({
    ..._.pick(employee, ['addition', 'city', 'description', 'employee', 'firstName', 'hourlyRate', 'houseNumber', 'lastName', 'postalCode', 'province', 'streetName', 'professions', 'province','dateOfBirth', 'specialty', 'vca']),
})

export default OfferedForm
