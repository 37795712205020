import React from 'react'
import { ComposeMessage, ContentHeader, ChatMessages } from '@/containers/Chat'
import { ChatContentWrapper, Spin, SpinWrapper } from '@/containers/Chat/styles/ChatContent.styles'

export const ChatContent = ({ chatRoom }) => {
    const { messages, ad } = chatRoom
    var data = chatRoom.proposals ? Object.assign(messages, chatRoom.proposals) : messages
    var combinedMessages = []
    if (data) {
        combinedMessages = Object.keys(data).map((key) => [key, data[key]])
        combinedMessages.sort((a, b) => a[1].messageTime - b[1].messageTime)
    }


    return (
        <ChatContentWrapper>
            <ContentHeader title={ad.title} />
            {combinedMessages ?
                <>
                    <ChatMessages ad={ad} messages={combinedMessages} chatRoom={chatRoom} />
                    <ComposeMessage ad={ad} chatRoom={chatRoom} />
                </> :
                <SpinWrapper>
                    <Spin />
                </SpinWrapper>}
        </ChatContentWrapper>
    )
}
