import React from 'react'
import Collection from './Collection'
import RemoveModal from './RemoveModal'
import AdModal from './AdModal'
import adsActions from '@/store/ads/actions'
import { OverviewWrapper } from "./styles/Overview.styles"
import { Button } from '@/components'
import { DashboardLayout } from '@/containers/DashboardLayout'
import { DashboardHeader } from '@/containers/DashboardLayout/DashboardHeader'
import { DashboardContent } from '@/containers/DashboardLayout/DashboardContent'
import { useDispatch } from 'react-redux'
import useDocumentTitle from '@/lib/helpers/appTitle'

const {
    setAdModalVisible,
} = adsActions

export const Lend = () => {
    useDocumentTitle('Koppelbaas - Uitlenen')
    const dispatch = useDispatch()
    return (
        <DashboardLayout>
            <DashboardHeader>
                <span className="header-title">Uitlenen</span>
                <Button
                    size="large"
                    type="primary"
                    style={{ marginRight: '16px', fontSize: '14px' }}
                    onClick={() => dispatch(setAdModalVisible(true))}>Nieuwe advertentie</Button>
            </DashboardHeader>
            <DashboardContent>
                <OverviewWrapper>
                    <Collection />
                    <RemoveModal />
                    <AdModal />
                </OverviewWrapper>
            </DashboardContent>
        </DashboardLayout>
    )
}