import React from 'react'
import appActions from '@/store/app/actions'
import { Layout, Menu, } from "@/components"
import { Link } from "react-router-dom"
import { auth } from '@/lib/firebase'
import { useDispatch, useSelector } from "react-redux"

const { Sider } = Layout
const { changeCurrent } = appActions

export const DashboardSider = () => {
    const dispatch = useDispatch()
    const { collapsed, current, width } = useSelector(state => state.App)

    return (
        <Sider
            style={{ animationDuration: '0s !important', position: 'fixed', height: '100vh', left: 0, overflow: 'auto' }}
            trigger={null}
            collapsible
            collapsed={collapsed && width < 992}
            collapsedWidth={0}>
            <div className="logo" />
            <Menu theme="dark" mode="inline" selectedKeys={current} onClick={e => dispatch(changeCurrent(e.key))}>
                <Menu.Item key="inlenen">
                    <Link to="/inlenen">Inlenen</Link>
                </Menu.Item>
                <Menu.Item key="uitlenen">
                    <Link to="/uitlenen">Uitlenen</Link>
                </Menu.Item>
                <Menu.Item key="chat">
                    <Link to="/chat">Chat</Link>
                </Menu.Item>
                <Menu.Item key="contracten">
                    <Link to="/contracten">Contracten</Link>
                </Menu.Item>
                <Menu.Item key="personeelsbestand">
                    <Link to='/personeelsbestand'>
                        Personeelsbestand
                    </Link>
                </Menu.Item>
                <Menu.Item key="mijn-gegevens">
                    <Link to='/profiel/mijn-gegevens'>
                        Mijn Gegevens
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="logout"
                    onClick={async () => await auth.signOut()}>
                    Uitloggen
                </Menu.Item>
            </Menu>
        </Sider >
    )
}
