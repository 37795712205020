import { all, takeEvery, put, take } from 'redux-saga/effects'
import firebase, { auth, db, storage } from '@/lib/firebase'
import actions from './actions'
import { buffers, END, eventChannel } from 'redux-saga'
import { getAuthErrorContent } from '@/lib/helpers'

const collectionPath = 'contractors'

function* getContractor() {

    const ref = db.collection(collectionPath).doc(auth.currentUser.uid)
    const channel = eventChannel(emit => ref.onSnapshot(emit, _ => emit(END)))

    try {
        while (true) {
            const snapshot = yield take(channel)

            const contractor = snapshot.data()

            yield put(actions.getContractorSuccess(contractor))
        }
    } catch (error) {
        yield put(actions.getContractorError(error))
    }

}

function* saveContractor({ payload }) {
    try {
        const { contractor } = payload

        if (contractor.email !== auth.currentUser.email) {
            yield auth.signInWithEmailAndPassword(auth.currentUser.email, contractor.password)
            yield auth.currentUser.updateEmail(contractor.email)
        }

        if (contractor.newPassword && contractor.confirmPassword) {
            yield auth.signInWithEmailAndPassword(auth.currentUser.email, contractor.password)
            yield auth.currentUser.updatePassword(contractor.newPassword)
        }

        delete contractor.password
        delete contractor.newPassword
        delete contractor.confirmPassword

        const ref = db.collection(collectionPath).doc(auth.currentUser.uid)
        yield ref.set(contractor, { merge: true })
        yield put(actions.saveContractorSuccess({ type: 'success', content: 'Wijzigen gelukt' }))
    } catch (error) {
        const content = getAuthErrorContent(error)
        yield put(actions.saveContractorError({ type: 'error', content }))
    }
}

function* upload({ payload }) {
    const { file } = payload

    const ref = storage
        .ref(`contractors/${auth.currentUser.uid}`)
        .child(file.name)

    const task = ref.put(file)

    const channel = eventChannel(emit => {

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

                emit({ progress })
            },
            error => {
                emit({ error })
            },
            () => {
                ref
                    .getDownloadURL()
                    .then(url => {
                        emit({ url })
                    })
            }
        )

        return () => {
            task.off("state_changed")
        }

    })

    try {
        while (true) {
            const { progress = 0, error, url } = yield take(channel, buffers.sliding(5));

            if (error) {
                yield put(actions.uploadError(error))
                channel.close()
                return
            }

            if (url) {
                const ref = db.collection(collectionPath).doc(auth.currentUser.uid)
                yield ref.set({ profileImageUrl: url }, { merge: true })
                yield put(actions.downloadURL(url))
                return
            }

            yield put(actions.uploadStart(progress))
        }
    } catch (error) {
        yield put(actions.uploadError(error))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_CONTRACTOR, getContractor),
        takeEvery(actions.SAVE_CONTRACTOR, saveContractor),
        takeEvery(actions.UPLOAD, upload),
    ]);
}