const ACTIONS = 'ADS_'

const actions = {
    GET_ADS: ACTIONS + 'GET_ADS',
    GET_ADS_ERROR: ACTIONS + 'GET_ADS_ERROR',
    GET_ADS_SUCCESS: ACTIONS + 'GET_ADS_SUCCESS',
    REMOVE_AD: ACTIONS + 'REMOVE_AD',
    REMOVE_AD_ERROR: ACTIONS + 'REMOVE_AD_ERROR',
    REMOVE_AD_SUCCESS: ACTIONS + 'REMOVE_AD_SUCCESS',
    REMOVE_FEEDBACK: ACTIONS + 'REMOVE_FEEDBACK',
    SAVE_AD: ACTIONS + 'SAVE_AD',
    SAVE_AD_ERROR: ACTIONS + 'SAVE_AD_ERROR',
    SAVE_AD_SUCCESS: ACTIONS + 'SAVE_AD_SUCCESS',
    SET_AD: ACTIONS + 'SET_AD',
    SET_AD_MODAL_VISIBLE: ACTIONS + 'SET_AD_MODAL_VISIBLE',
    SET_FILTERS: ACTIONS + 'SET_FILTERS',
    SET_REMOVE_MODAL_VISIBLE: ACTIONS + 'SET_REMOVE_MODAL_VISIBLE',
    SET_SHOW_FILTERS: ACTIONS + 'SET_SHOW_FILTERS',
    SET_TYPE: ACTIONS + 'SET_TYPE',
    TOGGLE_FILTERS: ACTIONS + 'TOGGLE_FILTERS',

    getAds: () => ({ type: actions.GET_ADS, }),
    getAdsSuccess: ads => ({ type: actions.GET_ADS_SUCCESS, payload: { ads }, }),
    getAdsError: error => ({ type: actions.GET_ADS_ERROR, payload: { error }, }),
    removeAd: ad => ({ type: actions.REMOVE_AD, payload: { ad }, }),
    removeAdSuccess: () => ({ type: actions.REMOVE_AD_SUCCESS, }),
    removeAdError: () => ({ type: actions.REMOVE_AD_ERROR, }),
    removeFeedback: () => ({ type: actions.REMOVE_FEEDBACK }),
    saveAd: (ad, data) => ({ type: actions.SAVE_AD, payload: { ad, data }, }),
    saveAdSuccess: feedback => ({ type: actions.SAVE_AD_SUCCESS, payload: { feedback }, }),
    saveAdError: feedback => ({ type: actions.SAVE_AD_ERROR, payload: { feedback }, }),
    setAd: ad => ({ type: actions.SET_AD, payload: { ad } }),
    setAdModalVisible: (adModalVisible, ad = "", data = {}) => ({ type: actions.SET_AD_MODAL_VISIBLE, payload: { adModalVisible, ad, data }, }),
    setFilters: filters => ({ type: actions.SET_FILTERS, payload: { filters }, }),
    setRemoveModalVisible: (removeModalVisible, ad) => ({ type: actions.SET_REMOVE_MODAL_VISIBLE, payload: { removeModalVisible, ad }, }),
    setShowFilters: showFilters => ({ type: actions.SET_SHOW_FILTERS, payload: { showFilters } }),
    setType: type => ({ type: actions.SET_TYPE, payload: { type } }),
    toggleFilters: () => ({ type: actions.TOGGLE_FILTERS, }),
}

export default actions
