import styled from 'styled-components'

const FilterTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 24px 0;
`

export {
  FilterTagsWrapper
}