import actions from './actions'
import { all, takeEvery, put, take } from 'redux-saga/effects'
import { auth, db } from '@/lib/firebase'
import { eventChannel, END } from "redux-saga"

const collectionPath = 'ads'

function* removeAd({ payload }) {
    const { ad } = payload

    try {
        const ref = yield db.collection(collectionPath).doc(ad)
        yield ref.set({ deletedAt: new Date().getTime(), }, { merge: true })
        yield put(actions.removeAdSuccess())
    } catch (error) {
        yield put(actions.removeAdError())
    }
}

function* getAds() {
    const ref = db
        .collection(collectionPath)
        .orderBy('createdAt', 'desc')
        .where('deletedAt', "==",null)

    const channel = eventChannel(emit => ref.onSnapshot(emit, _ => emit(END)))

    try {
        while (true) {
            const snapshot = yield take(channel)

            const ads = yield snapshot.docs.reduce((ads, doc) => {
                const data = doc.data()
                ads[doc.id] = { ...data }
                return ads
            }, {})

            yield put(actions.getAdsSuccess(ads))
        }
    } catch (error) {
        yield put(actions.getAdsError(error))
    }

}

function* saveAd({ payload }) {
    const { ad, data } = payload

    data['uid'] = auth.currentUser.uid
    data['updatedAt'] = new Date().getTime()

    try {
        if (ad) {
            // Update
            const ref = yield db.collection(collectionPath).doc(ad)
            yield ref.set(data, { merge: true })
        } else {
            // Insert
            const ref = yield db.collection(collectionPath)

            data['createdAt'] = new Date().getTime()
            data['deletedAt'] = null
            data['status'] = 'active'

            const addedAd = yield ref.add(data)
            yield ref.doc(addedAd.id).update({ key: addedAd.id })
        }

        yield put(actions.saveAdSuccess({ type: 'success', content: `${ad ? 'Wijzigen' : 'Opslaan'} gelukt` }))
    } catch (error) {
        yield put(actions.saveAdError({ type: 'error', content: 'Er ging iets mis' }))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_ADS, getAds),
        takeEvery(actions.REMOVE_AD, removeAd),
        takeEvery(actions.SAVE_AD, saveAd),
    ]);
}
