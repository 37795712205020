const ACTIONS = 'APP_'

const actions = {
    CHANGE_CURRENT: ACTIONS + 'CHANGE_CURRENT',
    TOGGLE_COLLAPSED: ACTIONS + 'TOGGLE_COLLAPSED',
    SET_TITLE: ACTIONS + 'SET_TITLE',

    changeCurrent: current => ({ type: actions.CHANGE_CURRENT, payload: { current }, }),
    toggleCollapsed: collapsed => ({ type: actions.TOGGLE_COLLAPSED, payload: { collapsed }, }),
    setTitle: title => ({ type: actions.SET_TITLE, payload: { title }, }),
}

export default actions