import styled from 'styled-components'
import { Spin as AntdSpin } from "@/components";

const ChatContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const Spin = styled(AntdSpin)``

const SpinWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${Spin} {
        align-self: center;
    }
`

export {
  ChatContentWrapper,
  Spin,
  SpinWrapper
}
