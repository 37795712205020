import actions from './actions'

const initState = {
    ad: "",
    adModalVisible: false,
    ads: undefined,
    chatRoom: "",
    creating: false,
    feedback: {},
    filters: { types: [], provinces: [], profession: undefined, period: null },
    loaded: false,
    loading: true,
    removing: false,
    saving: false,
    showFilters: false,
    showModal: false,
    type: "wanted",
}

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case actions.GET_ADS:
            return {
                ...state,
                loading: true,
            }
        case actions.GET_ADS_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
            }
        case actions.GET_ADS_SUCCESS:
            return {
                ...state,
                ads: payload.ads,
                loaded: true,
                loading: false,
            }
        case actions.REMOVE_AD:
            return {
                ...state,
                removing: true,
            }
        case actions.REMOVE_AD_ERROR:
            return {
                ...state,
                removing: false,
            }
        case actions.REMOVE_AD_SUCCESS:
            return {
                ...state,
                removeModalVisible: false,
                removing: false,
            }
        case actions.REMOVE_FEEDBACK:
            return {
                ...state,
                feedback: {},
            }
        case actions.SAVE_AD:
            return {
                ...state,
                saving: true,
            }
        case actions.SAVE_AD_ERROR:
            return {
                ...state,
                feedback: payload.feedback,
                saving: false,
            }
        case actions.SAVE_AD_SUCCESS:
            return {
                ...state,
                adModalVisible: false,
                feedback: payload.feedback,
                saving: false,
            }
        case actions.SET_AD:
            return {
                ...state,
                ad: payload.ad,
            }
        case actions.SET_AD_MODAL_VISIBLE:
            return {
                ...state,
                ad: payload.ad,
                adModalVisible: payload.adModalVisible,
                data: payload.data
            }
        case actions.SET_FILTERS:
            return {
                ...state,
                filters: payload.filters
            }
        case actions.SET_REMOVE_MODAL_VISIBLE:
            return {
                ...state,
                ad: payload.ad,
                removeModalVisible: payload.removeModalVisible,
            }
        case actions.SET_SHOW_FILTERS:
            return {
                ...state,
                showFilters: payload.showFilters,
            }
        case actions.SET_TYPE:
            return {
                ...state,
                type: payload.type
            }
        case actions.TOGGLE_FILTERS:
            return {
                ...state,
                showFilters: !state.showFilters
            }
        default:
            return state;
    }
}
