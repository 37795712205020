import _ from "lodash"
import actions from "../contracts/actions"

const initState = {
    contractsLoaded: false,
    contracts: [],
    error: ""
}

export default function reducer(state = initState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.GET_CONTRACTS:
            return {
                ...state
            }
        case actions.GET_CONTRACTS_SUCCESS:
            return {
                ...state,
                contractsLoaded: true,
                contracts: _.merge(state.contracts, payload.contracts)
            }
        case actions.GET_CONTRACTS_ERROR:
            return {
                ...state,
                contractsLoaded: true,
                error: payload.error
            }
        default:
            return state
    }
}