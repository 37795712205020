import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './reducers'
import sagas from './sagas'
import createSagaMiddleware from 'redux-saga'

const saga = createSagaMiddleware()
const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(saga),
)
const store = createStore(reducers, enhancer)

saga.run(sagas);

export default store