import React from 'react'
import PropTypes from 'prop-types'
import { Card, Skeleton, Avatar, Col, Space } from 'antd'
import { CalendarOutlined, CloudDownloadOutlined } from '@ant-design/icons'

const { Meta } = Card

const ContractListItem = ({doc}) => {
    const { employeeName } = doc.contract.employee
    const src = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'

    return (
        <Col span={{ xs: 24, md: 8 }}>
            <Card
                style={{ marginTop: 16, width: '100%' }}
                actions={[
                    <a target="_blank" rel="noreferrer" href={doc.url}>
                        <CloudDownloadOutlined style={{ fontSize: 20 }} />
                    </a>
                ]}
                bodyStyle={{ paddingBottom: 0 }}
            >
                <Skeleton loading={false} avatar active>
                    <Meta 
                        avatar={
                            <Avatar src={src} />
                        } 
                        title={employeeName}
                        description={
                            <Space>
                                <CalendarOutlined /><span>Van {doc.contract.startDate} tot {doc.contract.endDate}</span>
                            </Space>
                        }
                    />
                    <p style={{ marginTop: 16 }}> {doc.mine === true ? `Uitgeleend bij ${doc.contract.hirer.hirerCompanyName}` : `Ingeleend van ${doc.contract.lender.lenderCompanyName}`}</p>
                </Skeleton>
            </Card>
        </Col>
    )
}

ContractListItem.propTypes = {
    doc: PropTypes.object.isRequired,
}

export default ContractListItem
