import React, { createContext, useContext, useEffect, useState } from 'react';
import SpinContainer from '@/containers/styles/SpinContainer.styles';
import contractorsActions from '@/store/contractor/actions'
import authActions from '@/store/auth/actions'
import { Spin } from '@/components';
import { auth } from "@/lib/firebase";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const {
    getContractor,
    removeContractor
} = contractorsActions

const {
    verifyEmailAddressSuccess
} = authActions

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const [email, setEmail] = useState("")
    const [uid, setUid] = useState("")
    const [pending, setPending] = useState(true)
    const { emailVerified } = useSelector(state => state.Auth)

    useEffect(() => {
        return auth.onIdTokenChanged(user => {
            if (user) {
                dispatch(getContractor())
                dispatch(verifyEmailAddressSuccess(user.emailVerified))
                setEmail(user.email)
                setUid(user.uid)
            } else {
                dispatch(removeContractor())
                dispatch(verifyEmailAddressSuccess())
                setEmail("")
                setUid("")
                if (window.location.pathname !== '/registreren' && window.location.pathname !== '/inloggen' && window.location.pathname !== '/wachtwoord-resetten') {
                    history.push('/inloggen', { from: location })
                }
            }
            setPending(false)
        })
    }, [dispatch, history, location])

    if (pending) {
        return (
            <div style={{ display: 'flex', height: '100vh' }}>
                <SpinContainer>
                    <Spin />
                </SpinContainer>
            </div>
        )
    }

    return (
        <AuthContext.Provider value={{ email, emailVerified, uid }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}