import React, { useEffect, useState } from 'react'
import actions from "@/store/employees/actions"
import { Spin } from '@/components'
import { EmployeeListContainer, EmployeeListItem, Input, SearchContainer, SpinWrapper, WorkforceContainer } from "./styles/Employees.styles"
import { Link } from "react-router-dom"
import { useAuth } from '@/context/UserProvider'
import { useDispatch, useSelector } from "react-redux"

const {
    getEmployees,
} = actions
export const WorkforceSider = ({ active }) => {
    const dispatch = useDispatch()
    const { uid } = useAuth()
    const { loaded, employees } = useSelector(state => state.Employees)
    const [searchTerm, setSearchTerm] = useState("")
    const [currentEmployees, setCurrentEmployees] = useState(employees)

    useEffect(() => {
        if (!employees) {
            dispatch(getEmployees(uid))
        }
    }, [employees, dispatch, uid])

    useEffect(() => {
        let searchedEmployees = employees
        if (searchTerm.trim()) {
            searchedEmployees = Object.keys(searchedEmployees).filter(employee => {
                const { firstName, lastName } = employees[employee]
                return firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    `${firstName} ${lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
            }).reduce((searchedEmployees, employee) => {
                searchedEmployees[employee] = employees[employee]
                return searchedEmployees
            }, {})
        }
        setCurrentEmployees(searchedEmployees)
    }, [searchTerm, employees])

    const handleSearch = e => {
        setSearchTerm(e.target.value)
    }
    const singleEmployee = key => {
        const { firstName, lastName, profileImageUrl } = currentEmployees[key]
        const selected = active === key
        const style = {
            background: selected ? '#f7f7f7' : 'rgba(0,0,0,0)',
        }
        const src = profileImageUrl ?
            profileImageUrl :
            'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
        return (
            <Link to={`/personeelsbestand/${key}/bewerken`} style={{ width: '100%' }} key={key}>
                <EmployeeListItem style={style}>
                    <div className="employeeListItemGravatar">
                        <img
                            alt="#"
                            style={{ width: 45, height: 45 }}
                            src={src} />
                    </div>
                    <div className="employeeListItemContent">
                        <h4>{firstName} {lastName}</h4>
                    </div>
                </EmployeeListItem>
            </Link>
        )
    }
    return (
        <WorkforceContainer>
            {!employees ?
                <SpinWrapper>
                    <Spin style={{ alignSelf: 'center' }} />
                </SpinWrapper> :
                <>
                    <SearchContainer>
                        <Input onChange={handleSearch} placeholder="Medewerker zoeken" />
                    </SearchContainer>
                    <EmployeeListContainer>
                        {Object.keys(currentEmployees).length > 0 ?
                            Object.keys(currentEmployees).map(singleEmployee) :
                            <div style={{ padding: '15px 20px' }}>{searchTerm ? 'Geen zoekresultaten' : !loaded ? '' : 'U heeft nog geen personeel'}</div>
                        }
                    </EmployeeListContainer>
                </>
            }
        </WorkforceContainer>
    )
}
