import React, { useEffect } from 'react'
import adsActions from "@/store/ads/actions"
import { Col, Spin } from '@/components'
import { HireContent, HireLayout, HireSider } from "@/containers/Hire";
import { Row, SpinWrapper } from '@/pages/styles/inlenen.styles'
import { useDispatch, useSelector } from "react-redux";
import useDocumentTitle from '@/lib/helpers/appTitle';

const { getAds } = adsActions

export const HirePage = () => {
    useDocumentTitle('Koppelbaas - Inlenen')
    const dispatch = useDispatch()
    const { ads, loaded } = useSelector(state => state.Ads)

    useEffect(() => {
        if (!loaded) {
            dispatch(getAds())
        }
    }, [dispatch, loaded])

    return (
        <HireLayout>
            {!loaded ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <Row>
                    <Col {...({ xs: 24, md: 8, lg: 6 })}>
                        <HireSider />
                    </Col>
                    <Col {...({ xs: 24, md: 16, lg: 18 })}>
                        <HireContent ads={Object.fromEntries(Object.entries(ads).filter(x => x[1].status === 'active'))} />
                    </Col>
                </Row>}
        </HireLayout >
    )
}
