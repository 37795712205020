import { getView } from '@/lib/helpers/view'
import actions from './actions'

const intialState = {
    collapsed: window.innerWidth <= 1220,
    current: false,
    title: "",
    view: getView(window.innerWidth),
    width: window.innerWidth,
    height: window.innerHeight,
}

const reducer = (state = intialState, action) => {
    const { type, payload } = action
    switch (type) {
        case actions.CHANGE_CURRENT:
            return {
                ...state,
                current: payload.current,
            }
        case actions.TOGGLE_COLLAPSED:
            return {
                ...state,
                collapsed: payload.collapsed
            }
        case actions.SET_TITLE:
            return {
                ...state,
                title: payload.title
            }
        default:
            return state
    }
}

export default reducer
