import { combineReducers } from 'redux'

import Ads from './ads/reducer'
import App from './app/reducer'
import Auth from './auth/reducer'
import Chat from './chat/reducer'
import Contracts from './contracts/reducer'
import Employees from './employees/reducer'
import Contractor from './contractor/reducer'
import Email from './email/reducer'

export default combineReducers({
    Ads,
    App,
    Auth,
    Chat,
    Contracts,
    Employees,
    Contractor,
    Email,
})
