import Filters from './Filters'
import React from 'react'
import { Grid } from '@/components'
import { HireSiderWrapper } from './styles/HireSider.styles'
import { useSelector } from 'react-redux'

export const initialValues = { types: [], provinces: [], profession: undefined, period: null }
export const HireSider = () => {
    const screens = Grid.useBreakpoint()
    const { showFilters } = useSelector(state => state.Ads)
    return (
        <HireSiderWrapper>
            {(showFilters || screens['md']) &&
                <Filters />
            }
        </HireSiderWrapper>
    )
}
