import styled from 'styled-components'

const CollectionWrapper = styled.div`
    .ant-list-pagination {
        padding-right: 24px;
    }

    .ant-list-pagination {
        margin-bottom: 24px;
    }

    .ant-list-item {
        .ant-list-item-extra {
            margin-right: 0;
        }    
    }
    
    .ant-list-vertical .ant-list-item-extra {
        width: 100%;
        margin: 16px 0 0;
    }

    .ant-list-vertical .ant-list-item-action > li {
        padding-left: 0;
    }

    .ant-list-item-action-split {
        display: none;
    }
`

export {
    CollectionWrapper,
}