import Document from "./Document";
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { CollectionWrapper } from "./styles/Collection.styles";
import { List } from "@/components";
import { dateFormat } from '@/lib/helpers/date';
import { useSelector } from "react-redux";
import { useAuth } from "@/context/UserProvider";

const Collection = ({ ads }) => {
    const { uid } = useAuth()
    const { filters, loading } = useSelector(state => state.Ads)
    const [filteredAds, setFilteredAds] = useState(ads)

    useEffect(() => {
        setFilteredAds(
            Object.keys(ads).reduce((acc, ad) => {
                if (shouldShow(ads[ad], filters)) {
                    acc[ad] = ads[ad]
                }
                return acc
            }, {})
        )
    }, [filters, ads])

    return (
        <CollectionWrapper>
            <List
                locale={{ emptyText: "Geen advertenties gevonden" }}
                itemLayout="vertical"
                size="large"
                loading={loading}
                pagination={{
                    pageSize: 5,
                    showSizeChanger: false
                }}
                dataSource={!filteredAds ? [] : Object.keys(filteredAds).filter(ad => filteredAds[ad].uid !== uid)}
                renderItem={ad => {
                    return (
                        <Document ad={ad} />
                    )
                }} />
        </CollectionWrapper>
    )
}

const shouldShow = (ad, filters) => {
    const { types, profession, period, provinces } = filters
    const { employee } = ad

    const startDate = period ? moment(period[0].format(dateFormat), dateFormat) : undefined
    const endDate = period ? moment(period[1].format(dateFormat), dateFormat) : undefined

    if (moment(ad.period[1], dateFormat).isBefore(moment(moment().format(dateFormat), dateFormat))) return false

    if (types.length > 0 && !types.includes(ad.type)) return false

    if (employee) {
        if (
            (provinces.length > 0 ? !provinces.includes(ad.employee.province) : false) ||
            (startDate ? startDate.isBefore(moment(ad.period[0], dateFormat)) : false) ||
            (endDate ? endDate.isAfter(moment(ad.period[1], dateFormat)) : false) ||
            (profession ? !employee.professions.includes(profession) : false)
        ) {
            return false
        }
    } else {
        if (
            (provinces.length > 0 ? !provinces.filter(province => ad.provinces.includes(province)).length > 0 : false) ||
            (startDate ? startDate.isBefore(moment(ad.period[0], dateFormat)) : false) ||
            (endDate ? endDate.isAfter(moment(ad.period[1], dateFormat)) : false) ||
            (profession ? profession !== ad.profession : false)
        ) {
            return false
        }
    }

    return true
}


export default Collection