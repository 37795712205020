const professions = [
    "Asbestverwijderaar",
    "Asfaltcoordinator",
    "Asfaltwerker",
    "Balkman",
    "Bankwerker",
    "Betonafwerker",
    "Betonboorder",
    "Betonreparateur",
    "Betonstaalverwerker",
    "Betonstorter",
    "Betontimmerman",
    "Boormeester - horizontaal gestuurd boren",
    "Boormeester - verticaal boren",
    "Bouwkostendeskundige",
    "Bouwplaatshelper",
    "Bouwplaatsmonteur/ prefabmonteur",
    "Bronbemaler",
    "Calculator",
    "Chauffeur",
    "Constructeur",
    "Coördinator - grondwatertechniek",
    "Dakdekker - bitumen & Kunststof",
    "Dekvloerenlegger",
    "Elektromonteur - machines",
    "Engineer",
    "Funderingswerker - heier",
    "GWW medewerker",
    "Gevelbehandelaar",
    "Glaszetter",
    "Grondwerkers - weg- en waterbouw",
    "Heftruckchauffeur",
    "Hijsbegeleider/aanpikkelateur",
    "Houtbewerker",
    "Inkoper",
    "Isoleerder",
    "Kabelwerker - sleuvengraver",
    "Keukenmonteur",
    "Kitter/Purder",
    "Kraanmachinist",
    "Laadschopmachinist",
    "Lasser - buizen",
    "Lasser - kunststof",
    "Lasser",
    "Leidekker",
    "Leidinggevende van een discipline/afdeling",
    "Lijmer",
    "Loodgieter",
    "Maatvoerder",
    "Machinist (FO niet gebruiken enkel NOK)",
    "Machinist - slopen",
    "Machinist - wegenbouw",
    "Materieelbeheerder",
    "Medewerker - verkeerstechniek",
    "Medewerker bedrijfsbureau",
    "Menger - asfaltcentrale",
    "Metselaar",
    "Monteur - data & electra",
    "Monteur - gas/water/warmte",
    "Monteur mobiele werktuigen",
    "Monteur",
    "Ontwerper",
    "Opperman",
    "Pannenlegger",
    "Planner",
    "Projectcoördinator",
    "Projectleider",
    "Projectmanager",
    "Projectontwikkelaar",
    "Remmingwerker",
    "Rietdekker",
    "Rijswerker",
    "Rioleringsmedewerker",
    "Schilder",
    "Sloper",
    "Spuiter - plafond en wand",
    "Steenzetter",
    "Steigermonteur",
    "Straatmaker",
    "Stukadoor",
    "Tegelzetter",
    "Tekenaar",
    "Tendermanager",
    "Timmerman - werkplaats",
    "Timmerman",
    "Toezichthouder - Asbestverwijdering",
    "Toezichthouder - Bodemsaneringsprojecten",
    "Uitvoerder",
    "Uitzetter",
    "Vastgoedontwikkelaar",
    "Verkeerskundige",
    "Voeger",
    "Voeger/hakker",
    "Voorman",
    "Vrachtwagenchauffeur",
    "Walsmachinist",
    "Wand- en plafondmonteur",
    "Waterbouwer",
    "Wegmarkeerder",
    "Werkvoorbereider",
]

export default professions
