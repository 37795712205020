import React, { useState, useEffect } from 'react'
import {
    Input,
    SearchWrapper,
    ChatSiderWrapper
} from '@/containers/Chat/styles/ChatSider.styles'
import { useSelector } from "react-redux"
import { ChatRoomList } from './ChatRoomList'

export const ChatSider = () => {
    const { chatRooms, hirerChatRoomsLoaded, lenderChatRoomsLoaded } = useSelector(state => state.Chat)
    const [currentChatRooms, setCurrentChatRooms] = useState(chatRooms)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        let searchedChatRooms = chatRooms
        if (searchTerm.trim()) {
            searchedChatRooms = Object.keys(searchedChatRooms).filter(chatRoom => {
                return chatRooms[chatRoom].ad.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
            }).reduce((searchedChatRooms, chatRoom) => {
                searchedChatRooms[chatRoom] = chatRooms[chatRoom]
                return searchedChatRooms
            }, {})
        }
        setCurrentChatRooms(searchedChatRooms)
    }, [chatRooms, searchTerm])

    const handleSearch = e => {
        setSearchTerm(e.target.value)
    }

    return (
        <ChatSiderWrapper>
            <SearchWrapper>
                <Input onChange={handleSearch} placeholder="Gesprek zoeken" />
            </SearchWrapper>
            {
                Object.keys(currentChatRooms).length > 0 ?
                    <ChatRoomList
                        chatRooms={Object.keys(currentChatRooms).map(chatRoom => chatRooms[chatRoom])} /> :
                    <div style={{ padding: '15px 20px' }}>{searchTerm ? 'Geen zoekresultaten' : (hirerChatRoomsLoaded && lenderChatRoomsLoaded) ? '' : 'U heeft nog geen gesprekken'}</div>
            }
        </ChatSiderWrapper>
    )
}
