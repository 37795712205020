import DetailsForm from './DetailsForm'
import React from 'react'
import { DashboardContent } from '@/containers/DashboardLayout/DashboardContent'
import { DashboardHeader } from '@/containers/DashboardLayout/DashboardHeader'
import { DashboardLayout } from '@/containers/DashboardLayout'
import { OverviewWrapper } from './styles/index.styles'
import useDocumentTitle from '@/lib/helpers/appTitle'

export const MyDetails = () => {
    useDocumentTitle('Koppelbaas - Mijn gegevens')
    return (
        <DashboardLayout>
            <DashboardHeader>
                <span className="header-title">Mijn Gegevens</span>
            </DashboardHeader>
            <DashboardContent>
                <OverviewWrapper>
                    <DetailsForm />
                </OverviewWrapper>
            </DashboardContent>
        </DashboardLayout>
    )
}