import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Form, Card, Input, Layout, message } from '@/components';
import bg from "@/assets/images/bg.jpg";
import logo from "@/assets/images/logo.png";
import { useAuth } from '@/context/UserProvider';
import actions from '@/store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import useDocumentTitle from '@/lib/helpers/appTitle';

const { Content } = Layout;
const {
    login,
    authFeedback
} = actions

export const Login = () => {
    useDocumentTitle('Koppelbaas - Inloggen')
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const { uid, emailVerified } = useAuth()
    const [form] = Form.useForm()
    const { loading, feedback } = useSelector(state => state.Auth)

    const handleFinish = async ({ email, password }) => {
        dispatch(login(email, password))
    }
    const { state } = location

    useEffect(() => {
        if (feedback) {
            message[feedback.type](feedback.content)
            dispatch(authFeedback())
        }
    }, [feedback])

    useEffect(() => {
        if (uid) {
            if (!emailVerified) {
                history.push('/e-mailadres-bevestigen')
            } else if (state && state.from) {
                history.replace(state.from)
            } else {
                history.push('/inlenen')
            }
        }
    }, [uid, history, emailVerified])

    return (
        <Layout style={{ height: '100vh' }}>
            <Layout>
                <Content style={{ backgroundImage: `url(${bg})` }}>
                    <Row justify="space-around" align="middle" style={{ height: "100%" }}>
                        <Col xs={24} sm={20} md={14} lg={10} xl={8} xxl={6}>
                            <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                                <img style={{ maxWidth: '250px' }} src={logo} alt={"koppelbaas-logo"} />
                            </div>
                            <Card bordered={false}
                                style={{ paddingTop: '6px' }}
                                headStyle={{ color: '#e7bc38', fontSize: '2em', borderBottom: 'none' }}>
                                <Form
                                    form={form}
                                    onFinish={handleFinish}>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vul uw e-mailadres in',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Vul een geldig e-mailadres in',
                                            },
                                        ]}
                                        validateTrigger="onBlur">
                                        <Input size="large" placeholder="e-mailadres" />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vul uw wachtwoord in',
                                            },
                                        ]}>
                                        <Input.Password size="large" placeholder="wachtwoord" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}>
                                            Inloggen
                                        </Button>
                                        <div style={{ float: 'right' }}>
                                            <Link to="/wachtwoord-resetten">
                                                Wachtwoord vergeten?
                                            </Link>
                                        </div>
                                    </Form.Item>
                                </Form>
                                Nog geen account? <Link to="/registreren">Registreer hier</Link><br />
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    )
}
