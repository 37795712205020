import './App.less'
import React from 'react'
import common_nl from "@/translations/nl/common.json"
import i18next from "i18next"
import store from '@/store'
import themes from "@/config/theme/theme.config"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { AuthProvider } from '@/context/UserProvider'
import { I18nextProvider } from "react-i18next"
import { Lend, Login, MyDetails, Register } from '@/containers'
import { ChatPage, ChatRoomPage, EditEmployeePage, HirePage, NewEmployeePage, VerifyEmailAddressPage, WorkforcePage, ResetPasswordPage } from '@/pages'
import { PrivateRoute, AuthRoute } from '@/components'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ContractsPage } from './pages/contracts'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'nl',
  resources: {
    nl: {
      common: common_nl
    },
  },
});

const App = () => (
  <ThemeProvider theme={themes['defaultTheme']}>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/inlenen" Component={HirePage} />
              <PrivateRoute exact path="/uitlenen" Component={Lend} />
              <PrivateRoute exact path="/profiel/mijn-gegevens" Component={MyDetails} />
              <PrivateRoute exact path="/personeelsbestand" Component={WorkforcePage} />
              <PrivateRoute exact path="/personeelsbestand/nieuw" Component={NewEmployeePage} />
              <PrivateRoute exact path="/personeelsbestand/:employee/bewerken" Component={EditEmployeePage} />
              <PrivateRoute exact path="/chat" Component={ChatPage} />
              <PrivateRoute exact path="/chat/:chatRoom" Component={ChatRoomPage} />
              <PrivateRoute exact path="/contracten" Component={ContractsPage} />
              <AuthRoute exact path="/inloggen" Component={Login} />
              <AuthRoute exact path="/registreren" Component={Register} />
              <AuthRoute exact path="/e-mailadres-bevestigen" Component={VerifyEmailAddressPage} />
              <AuthRoute exact path="/wachtwoord-resetten" Component={ResetPasswordPage} />
              <Route path="/">
                <Redirect to="/inloggen" />
              </Route>
            </Switch>
          </AuthProvider>
        </Router>
      </I18nextProvider>
    </Provider>
  </ThemeProvider>
);

export default App;
