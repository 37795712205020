import React, { useEffect } from 'react'
import actions from '@/store/ads/actions'
import Document from "./Document";
import { List } from "@/components";
import { useDispatch, useSelector } from "react-redux";
import { CollectionWrapper } from "./styles/Collection.styles";
import { useAuth } from '@/context/UserProvider';

const {
    getAds,
} = actions
const Collection = () => {
    const {uid} = useAuth()
    const dispatch = useDispatch()
    const { ads, loading } = useSelector(state => state.Ads)

    useEffect(() => {
        if (!ads) {
            dispatch(getAds())
        }
    }, [ads, dispatch])

    return (
        <CollectionWrapper>
            <List
                locale={{ emptyText: "U heeft nog geen advertenties" }}
                itemLayout="vertical"
                size="large"
                loading={loading}
                pagination={{
                    pageSize: 5,
                }}
                dataSource={!ads ? [] : Object.keys(ads).filter(ad => (ads[ad].lender?.key || ads[ad].hirer?.key) === uid)}
                renderItem={ad => {
                    return (
                        <Document ad={ad} />
                    )
                }} />
        </CollectionWrapper>
    )
}

export default Collection