import React, { useEffect, useState } from 'react'
import { Checkbox, DatePicker, Form, InputNumber, TimePicker, Select } from '@/components'
import { dateFormat } from '@/lib/helpers/date'
import moment from 'moment'
import employeesActions from "@/store/employees/actions"
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '@/lib/helpers/utility'
const {
    getEmployees,
} = employeesActions

const { Item } = Form
const { Option } = Select

const ContractForm = ({ form, ad, chatRoom }) => {
    const dispatch = useDispatch()
    const [selectedEmployee, setSelectedEmployee] = useState(undefined)
    const { employees } = useSelector(state => state.Employees)
    const user = getUser()

    const workingDays = [
        { label: 'Ma', value: 'Ma' },
        { label: 'Di', value: 'Di' },
        { label: 'Wo', value: 'Wo' },
        { label: 'Do', value: 'Do' },
        { label: 'Vr', value: 'Vr' },
    ]

    useEffect(() => {
        dispatch(getEmployees())
    }, [dispatch])

    useEffect(() => {
        if (ad.type === 'wanted') {
            form.setFieldsValue({'hourlyRate': Object.values(employees).filter(x => x.id === selectedEmployee)[0]?.hourlyRate})
        } else {
            form.setFieldsValue({'hourlyRate': ad.employee ? ad.employee.hourlyRate : 0})
        }
    }, [ad, form, employees, selectedEmployee])

    const disabledDate = (current) => current < moment(ad.period[0], ['DD-MM-YYYY']) || current > moment(ad.period[1], ['DD-MM-YYYY'])

    return (
        <Form
            style={{ marginTop: '20px' }}
            form={form}
            labelAlign="left"
            requiredMark={false}
            colon={false}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            {ad.type === 'wanted' && chatRoom.lender.key === user.uid &&
            <Item
                name="employee"
                label="Werknemer"
                rules={[{ required: true, message: 'Employee kiezen is verplicht' }]}>
                <Select onChange={(id) => setSelectedEmployee(id)}>
                    {Object.values(employees).map(x => <Option key={x.id} value={x.id}>{x.firstName} {x.lastName}</Option>)}
                </Select>
            </Item>}
            <Item
                name="workingDays"
                label="Werkdagen"
                initialValue={[]}
                rules={[{ required: true, message: 'Werkdagen zijn verplicht' }]}>
                <Checkbox.Group options={workingDays} />
            </Item>
            <Item
                name="workingHours"
                label="Werktijden"
                initialValue={[moment('09:00', 'HH:mm'), moment('17:00', 'HH:mm')]}
                rules={[{ required: true, message: 'Werktijden zijn verplicht' }]}>
                <TimePicker.RangePicker
                    minuteStep={15}
                    format="HH:mm"
                    placeholder={["Begintijd", "Eindtijd"]} />
            </Item>
            <Form.Item
                name="hourlyRate"
                label="Uurtarief"
                rules={[{ required: true, message: 'Een uurtarief is verplicht' }]}>
                <InputNumber
                    decimalSeparator={","}
                    precision={2}
                    min={0}
                    disabled={ad.employee !== undefined && chatRoom.lender.key === user.uid}
                    formatter={value => `€ ${value}`} />
            </Form.Item>
            <Form.Item
                name="period"
                label="Periode"
                initialValue={[moment(ad.period[0], ['DD-MM-YYYY']), moment(ad.period[1], ['DD-MM-YYYY'])]}
                rules={[{ required: true, message: 'Periode is verplicht' }]}>
                <DatePicker.RangePicker
                    placeholder={["Begindatum", "Einddatum"]}
                    disabledDate={disabledDate}
                    format={dateFormat} />
            </Form.Item>
        </Form>
    )
}

export default ContractForm