const ACTIONS = 'CHAT_'

const actions = {
    ACCEPT_CONTRACT: ACTIONS + 'ACCEPT_CONTRACT',
    ACCEPT_CONTRACT_SUCCESS: ACTIONS + 'ACCEPT_CONTRACT_SUCCESS',
    ADD_MESSAGE: ACTIONS + 'ADD_MESSAGE',
    ADD_MESSAGE_ERROR: ACTIONS + 'ADD_MESSAGE_ERROR',
    ADD_MESSAGE_SUCCESS: ACTIONS + 'ADD_MESSAGE_SUCCESS',
    CANCEL_CONTRACT: ACTIONS + 'CANCEL_CONTRACT',
    CANCEL_CONTRACT_SUCCESS: ACTIONS + 'CANCEL_CONTRACT_SUCCESS',
    CREATE_CHAT_ROOM: ACTIONS + 'CREATE_CHAT_ROOM',
    CREATE_CHAT_ROOM_ERROR: ACTIONS + 'CREATE_CHAT_ROOM_ERROR',
    CREATE_CHAT_ROOM_SUCCESS: ACTIONS + 'CREATE_CHAT_ROOM_SUCCESS',
    DENY_CONTRACT: ACTIONS + 'DENY_CONTRACT',
    DENY_CONTRACT_SUCCESS: ACTIONS + 'DENY_CONTRACT_SUCCESS',
    GET_CHAT_ROOMS: ACTIONS + 'GET_CHAT_ROOMS',
    GET_CHAT_ROOMS_ERROR: ACTIONS + 'GET_CHAT_ROOMS_ERROR',
    GET_CHAT_ROOMS_SUCCESS: ACTIONS + 'GET_CHAT_ROOMS_SUCCESS',
    GET_MESSAGES: ACTIONS + 'GET_MESSAGES',
    GET_MESSAGES_ERROR: ACTIONS + 'GET_MESSAGES_ERROR',
    GET_MESSAGES_SUCCESS: ACTIONS + 'GET_MESSAGES_SUCCESS',
    GET_PROPOSALS: ACTIONS + 'GET_PROPOSALS',
    GET_PROPOSALS_ERROR: ACTIONS + 'GET_PROPOSALS_ERROR',
    GET_PROPOSALS_SUCCESS: ACTIONS + 'GET_PROPOSALS_SUCCESS',
    REMOVE_CHAT_ROOM: ACTIONS + 'REMOVE_CHAT_ROOM',
    SEND_CONTRACT: ACTIONS + 'SEND_CONTRACT',
    SEND_CONTRACT_ERROR: ACTIONS + 'SEND_CONTRACT_ERROR',
    SEND_CONTRACT_SUCCESS: ACTIONS + 'SEND_CONTRACT_SUCCESS',
    TOGGLE_CONTRACT_MODAL: ACTIONS + 'TOGGLE_CONTRACT_MODAL',

    acceptContract: (chatRoom, contract) => ({ type: actions.ACCEPT_CONTRACT, payload: { chatRoom, contract } }),
    acceptContractSuccess: () => ({ type: actions.ACCEPT_CONTRACT_SUCCESS }),
    addMessage: (chatRoom, data) => ({ type: actions.ADD_MESSAGE, payload: { chatRoom, data } }),
    addMessageSuccess: (chatRoom, message, data) => ({ type: actions.ADD_MESSAGE_SUCCESS, payload: { chatRoom, message, data } }),
    addMessageError: error => ({ type: actions.ADD_MESSAGE_SUCCESS, payload: { error } }),
    cancelContract: (chatRoom, contract) => ({ type: actions.CANCEL_CONTRACT, payload: { chatRoom, contract } }),
    cancelContractSuccess: () => ({ type: actions.CANCEL_CONTRACT_SUCCESS }),
    createChatRoom: (ad, uid) => ({ type: actions.CREATE_CHAT_ROOM, payload: { ad, uid }, }),
    createChatRoomError: error => ({ type: actions.CREATE_CHAT_ROOM_ERROR, payload: { error }, }),
    createChatRoomSuccess: chatRoom => ({ type: actions.CREATE_CHAT_ROOM_SUCCESS, payload: { chatRoom }, }),
    denyContract: (chatRoom, contract) => ({ type: actions.DENY_CONTRACT, payload: { chatRoom, contract } }),
    denyContractSuccess: () => ({ type: actions.DENY_CONTRACT_SUCCESS }),
    getChatRooms: uid => ({ type: actions.GET_CHAT_ROOMS, payload: { uid } }),
    getChatRoomsError: error => ({ type: actions.GET_CHAT_ROOMS_ERROR, payload: { error } }),
    getChatRoomsSuccess: chatRooms => ({ type: actions.GET_CHAT_ROOMS_SUCCESS, payload: { chatRooms } }),
    getMessages: chatRoom => ({ type: actions.GET_MESSAGES, payload: { chatRoom } }),
    getMessagesSuccess: (chatRoom, messages) => ({ type: actions.GET_MESSAGES_SUCCESS, payload: { chatRoom, messages } }),
    getMessagesError: error => ({ type: actions.GET_MESSAGES_ERROR, payload: { error } }),
    getProposals: chatRoom => ({ type: actions.GET_PROPOSALS, payload: { chatRoom } }),
    getProposalsSuccess: (chatRoom, proposals) => ({ type: actions.GET_PROPOSALS_SUCCESS, payload: { chatRoom, proposals } }),
    getProposalsError: error => ({ type: actions.GET_PROPOSALS_ERROR, payload: { error } }),
    removeChatRoom: () => ({ type: actions.REMOVE_CHAT_ROOM }),
    sendContract: (chatRoom, contract, message) => ({ type: actions.SEND_CONTRACT, payload: { chatRoom, contract, message } }),
    sendContractError: error => ({ type: actions.SEND_CONTRACT_ERROR, payload: { error } }),
    sendContractSuccess: () => ({ type: actions.SEND_CONTRACT_SUCCESS }),
    toggleContractModal: () => ({ type: actions.TOGGLE_CONTRACT_MODAL })
}

export default actions