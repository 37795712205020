export const getAuthErrorContent = ({ code }) => {
    console.log(code)
    switch (code) {
        case 'auth/weak-password':
            return 'Vul een sterker wachtwoord in'
        case 'auth/argument-error':
            return 'Deze link lijkt niet te werken. Probeer het nog eens door een nieuwe link aan te vragen'
        case 'auth/invalid-email':
            return 'Vul een geldig e-mailadres in'
        case 'auth/user-not-found':
            return 'Onjuist e-mailadres of wachtwoord'
        case 'auth/wrong-password':
            return 'Onjuist e-mailadres of wachtwoord'
        case 'too-many-requests':
            return 'U probeert te vaak in te loggen. Probeer het later nog eens'
        case 'auth/email-already-in-use':
            return 'Dit e-mailadres staat al geregistreerd. Probeer in te loggen'
        case 'auth/invalid-action-code':
            return 'Deze link lijkt niet te werken. Probeer het nog eens door een nieuwe link aan te vragen'
        default:
            return 'Er ging iets mis. Probeer het later nog eens'
    }
}
