import React, { useEffect } from 'react'
import chatActions from '@/store/chat/actions'
import { ChatLayout, ChatSider } from '@/containers/Chat'
import { Col, Grid, Row, Spin } from '@/components'
import { SpinWrapper } from '@/pages/styles/chat.styles'
import { useAuth } from '@/context/UserProvider'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import useDocumentTitle from '@/lib/helpers/appTitle'

const {
    getChatRooms,
} = chatActions

export const ChatPage = () => {
    useDocumentTitle('Koppelbaas - Chat')
    const history = useHistory()
    const dispatch = useDispatch()
    const screens = Grid.useBreakpoint()
    const { chatRoomsLoaded, chatRooms } = useSelector(state => state.Chat)
    const { uid } = useAuth()

    useEffect(() => {
        if (!chatRoomsLoaded) {
            dispatch(getChatRooms(uid))
        }
    }, [dispatch, chatRoomsLoaded, uid])

    useEffect(() => {
        if (screens['md'] && chatRoomsLoaded && Object.keys(chatRooms).length > 0) {
            history.push(`/chat/${Object.keys(chatRooms)[0]}`)
        }
    }, [chatRooms, history, chatRoomsLoaded, screens])

    const EmptyChats = styled.div`
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
    `

    return (
        <ChatLayout>
            {!chatRoomsLoaded ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <Row style={{ width: '100%' }}>
                    <Col {...{ xs: 24, md: 8, lg: 6 }}>
                        <ChatSider />
                    </Col>
                    {(chatRoomsLoaded && Object.keys(chatRooms).length <= 0) &&
                        <Col {...({ xs: 0, md: 16, lg: 18 })}>
                            <EmptyChats>
                                <p style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
                                    Oops, het lijkt erop dat je nog geen chats hebt,
                                    ga naar inlenen en begin met chatten.
                                </p>
                                <Link className='ant-btn ant-btn-primary' to="/inlenen">Naar inleen-overzicht</Link>
                            </EmptyChats>
                        </Col>
                    }
                </Row>}
        </ChatLayout >
    )
}