const ACTIONS = 'CONTRACTORS_'

const actions = {
    DOWNLOAD_URL: ACTIONS + 'DOWNLOAD_URL',
    GET_CONTRACTOR: ACTIONS + 'GET_CONTRACTOR',
    GET_CONTRACTOR_ERROR: ACTIONS + 'GET_CONTRACTOR_ERROR',
    GET_CONTRACTOR_SUCCESS: ACTIONS + 'GET_CONTRACTOR_SUCCESS',
    REMOVE_FEEDBACK: ACTIONS + 'REMOVE_FEEDBACK',
    REMOVE_CONTRACTOR: ACTIONS + 'REMOVE_CONTRACTOR',
    SAVE_CONTRACTOR: 'SAVE_CONTRACTOR',
    SAVE_CONTRACTOR_ERROR: 'SAVE_CONTRACTOR_ERROR',
    SAVE_CONTRACTOR_SUCCESS: 'SAVE_CONTRACTOR_SUCCESS',
    UPLOAD: ACTIONS + 'UPLOAD',
    UPLOAD_ERROR: ACTIONS + 'UPLOAD_ERROR',
    UPLOAD_START: ACTIONS + 'UPLOAD_START',

    downloadURL: url => ({ type: actions.DOWNLOAD_URL, payload: { url } }),
    getContractor: () => ({ type: actions.GET_CONTRACTOR }),
    getContractorError: error => ({ type: actions.GET_CONTRACTOR_ERROR, payload: { error } }),
    getContractorSuccess: contractor => ({ type: actions.GET_CONTRACTOR_SUCCESS, payload: { contractor } }),
    removeFeedback: () => ({ type: actions.REMOVE_FEEDBACK }),
    removeContractor: () => ({ type: actions.REMOVE_CONTRACTOR }),
    saveContractor: contractor => ({ type: actions.SAVE_CONTRACTOR, payload: { contractor }, }),
    saveContractorError: feedback => ({ type: actions.SAVE_CONTRACTOR_ERROR, payload: { feedback }, }),
    saveContractorSuccess: feedback => ({ type: actions.SAVE_CONTRACTOR_SUCCESS, payload: { feedback } }),
    upload: file => ({ type: actions.UPLOAD, payload: { file }, }),
    uploadError: error => ({ type: actions.UPLOAD_ERROR, payload: { error }, }),
    uploadStart: uploadProgress => ({ type: actions.UPLOAD_START, payload: { uploadProgress }, }),
}

export default actions
