import React, { useEffect, useState } from 'react'
import { ChatRoomListItemWrapper } from '@/containers/Chat/styles/ChatRoomListItem.styles'
import { Link, useParams } from 'react-router-dom'
import { timeDifference } from "@/lib/helpers/utility";
import moment from 'moment';
import _ from 'lodash'

export const ChatRoomListItem = ({ chatRoom, otherContractor }) => {
    const { key, message, ad } = chatRoom
    const { profileImageUrl } = otherContractor
    const selected = useParams().chatRoom === chatRoom.key
    const src = profileImageUrl || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
    const [latestMessageOrProposal, setLatestMessageOrProposal] = useState(undefined)

    useEffect(() => {
        const { proposals, message } = chatRoom;
        if (!message && (_.isEmpty(proposals) || !proposals)) return;
        if (_.isEmpty(proposals) && message) {
            setLatestMessageOrProposal(message)
            return
        }
        let proposal = Object.values(proposals).sort((a,b) => moment(b.messageTime) - moment(a.messageTime))[0]
        setLatestMessageOrProposal(!message ? proposal : moment(proposal.messageTime) > moment(message.messageTime) ? proposal : message)
    }, [chatRoom])

    return (
        <Link to={`/chat/${key}`} style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
            <ChatRoomListItemWrapper selected={selected}>
                <div className="chatRoomListItemGravatar">
                    <img
                        alt="#"
                        src={src} />
                </div>
                <div className="chatRoomListItemContent">
                    <h4>{ad.title.substring(0, 30)}{ad.title.length > 30 && '...'}</h4>
                        {message && <div className="chatExcerpt">
                        <p>{!latestMessageOrProposal ? '' : (latestMessageOrProposal.text ? latestMessageOrProposal.text : 'Contract voorstel')}</p>
                        <span className="userListsTime">
                            {latestMessageOrProposal ? timeDifference(latestMessageOrProposal.messageTime) : ''}
                        </span>
                    </div>}
                </div>
            </ChatRoomListItemWrapper>
        </Link>
    )
}
