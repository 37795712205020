import styled from 'styled-components'
import { palette } from 'styled-theme'

const AppHolder = styled.div`
    .trigger {
        font-size: 18px;
        padding: 22.5px;
        cursor: pointer;
        transition: color 0.3s;
        border-right: 1px solid ${palette('border', 0)};
    }
  
    .ant-layout-header {
        padding: 0;
        border-bottom: 1px solid ${palette('border', 0)};
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-title {
            font-size: 18px;
            padding-left: 20px;
        }
    }

    .ant-layout-sider, 
    .ant-layout-sider *, 
    .ant-layout-sider .ant-menu-item-icon + span {
        transition: none;
    }

    .ant-layout-content {
        display: flex;
        flex: 1 0 auto;
        justify-content: start;
        margin: 24px 16px 0;
        flex-direction: column;
    }

    .ant-layout-content > div {
        flex: 1 0 auto;
    }

    .logo {
        height: 32px;
        background-image: url(/koppelbaas.png);
        margin: 16px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
`

export {
    AppHolder
}