import styled from 'styled-components';
import { Input, Button as AntdButton } from "@/components";
import { palette } from 'styled-theme'

const AntdTextArea = Input.TextArea

const TextArea = styled(AntdTextArea)``

const ComposeMessageWrapper = styled.div`
  border-top: 1px solid ${palette('border', 0)};
  border-bottom: 0;
  flex: 0 0 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;

  textarea {
    border-radius: 20px;
    margin-right: 10px;
  }
`

const Button = styled(AntdButton)`
  width: calc(100% - 60px);
  margin-left: 30px;
  margin-top: 30px;
  span {
    &:last-child {
      span {
        background-color: #ffffff;
      }
    }
  }
`

const SendIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100%;
  width: 14%;
`

export {
  ComposeMessageWrapper,
  TextArea,
  Button,
  SendIconContainer,
}