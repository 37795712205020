import styled from 'styled-components'

const OverviewWrapper = styled.div`
  width: 100%;
  display: 'flex';
  minHeight: '100%';
  background: #fff;
  border: 1px solid rgb(240, 240, 240);  
  padding-top: 32px;
`

export {
  OverviewWrapper,
}