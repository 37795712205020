import React, { useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import professions from '@/lib/helpers/professions'
import provinces from '@/lib/helpers/provinces'
import { DatePicker, Form, InputNumber, Select, Input, Radio, Slider } from '@/components'
import { dateFormat } from "@/lib/helpers/date"
import { useSelector } from "react-redux";

const { Option } = Select
const { RangePicker } = DatePicker
const { TextArea } = Input

const defaultValues = {
    profession: professions[0],
    provinces: [],
    age: [18, 67],
    period: [moment(), moment().add(7, 'days')],
    description: "Met spoed op zoek naar een ...",
    vca: 'VCA-vol',
    maxHourlyRate: 50
}

const WantedForm = ({ form }) => {
    const { ad, adModalVisible, ads } = useSelector(state => state.Ads)

    useEffect(() => {
        if (ad && adModalVisible) {
            form.setFieldsValue(getAdFields(ads[ad]))
        } else {
            form.setFieldsValue(defaultValues)
        }
    }, [ad, ads, form, adModalVisible])

    return (
        <Form
            form={form}
            labelAlign="left"
            requiredMark={false}
            colon={false}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Form.Item
                name="title"
                label="Titel"
                rules={[{ required: true, message: 'Titel is verplicht' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="description"
                label="Beschrijving"
                rules={[{ required: true, message: 'Beschrijving is verplicht' }]}>
                <TextArea allowClear />
            </Form.Item>
            <Form.Item
                name="profession"
                label="Functie"
                rules={[{ required: true, message: 'Functie is verplicht' }]}>
                <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {professions.map(profession => <Option key={profession} value={profession}>{profession}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name="provinces"
                label="Provincies"
                rules={[{ required: true, message: 'Een provincie is verplicht' }]}>
                <Select
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {Object.keys(provinces).map(province => <Option key={province} value={province}>{province}</Option>)}
                </Select>
            </Form.Item>
            <Form.Item
                name="age"
                label="Leeftijd"
                rules={[{ required: true, message: 'Leeftijd is verplicht' }]}>
                <Slider
                    range
                    min={18}
                    max={67} />
            </Form.Item>
            <Form.Item
                name="period"
                label="Periode"
                rules={[{ required: true, message: 'Periode is verplicht' }]}>
                <RangePicker
                    placeholder={["Begindatum", "Einddatum"]}
                    format={dateFormat} />
            </Form.Item>
            <Form.Item
                name="vca"
                label="VCA"
                rules={[{ required: true, message: 'VCA is verplicht' }]}>
                <Radio.Group>
                    <Radio value="VCA-vol">VCA-vol</Radio>
                    <Radio value="VCA-basis">VCA-basis</Radio>
                    <Radio value="geen VCA">geen VCA</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="maxHourlyRate"
                label="Maximale uurtarief"
                rules={[{ required: true, message: 'Maximale uurtarief is verplicht' }]}>
                <InputNumber
                    decimalSeparator={","}
                    precision={2}
                    formatter={value => `€ ${value}`} />
            </Form.Item>
        </Form>
    )
}

const getAdFields = ad => ({
    ..._.pick(ad, ['age', 'maxTravelDistance', 'period', 'profession', 'provinces', 'title']),
    period: ad.period.map(date => moment(date, dateFormat)),
})

export default WantedForm
