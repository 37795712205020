import ContractForm from './ContractForm'
import React from 'react'
import chatActions from '@/store/chat/actions'
import { Form, Modal } from '@/components'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '@/context/UserProvider'
import { dateFormat } from '@/lib/helpers/date'
import { getUser } from '@/lib/helpers/utility'

const {
    sendContract,
    toggleContractModal,
} = chatActions

const getHours = (workingDays, workingHours) => (Math.round(workingHours[1].diff(workingHours[0], 'hours', true) * 100) / 100) * workingDays.length

const CreateContractModal = ({ ad, chatRoom }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { uid } = useAuth()
    const user = getUser()
    const { contractModalVisible, sendingContract } = useSelector(state => state.Chat)
    const { employees } = useSelector(state => state.Employees)
    const handleOk = async () => {
        try {
            await form.validateFields()
            const { hourlyRate, period, workingDays, workingHours, employee } = form.getFieldsValue()
            const selectedEmployee = Object.values(employees).filter(x => x.id === employee)[0]
            const contract = {
                basis: "week",
                city: ad.type === "wanted" ? chatRoom.lender.city : chatRoom.hirer.city,
                workingDays: workingDays,
                days: period[1].diff(period[0], 'days') + 1,
                employee: ad.type === 'wanted' ? {
                    employeeName: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
                    employeeDateOfBirth: selectedEmployee.dateOfBirth,
                    employeePostalCode: selectedEmployee.postalCode,
                    employeeCity: selectedEmployee.city,
                    employeeAddress: `${selectedEmployee.streetName} ${selectedEmployee.houseNumber}`,
                    employeeProfessions: selectedEmployee.professions.join('/'),
                    key: selectedEmployee.id,
                } : {
                    employeeName: `${ad.employee.firstName} ${ad.employee.lastName}`,
                    employeeDateOfBirth: ad.employee.dateOfBirth,
                    employeePostalCode: ad.employee.postalCode,
                    employeeCity: ad.employee.city,
                    employeeAddress: `${ad.employee.streetName} ${ad.employee.houseNumber}`,
                    employeeProfessions: ad.employee.professions.join('/'),
                },
                endDate: period[1].format(dateFormat),
                fte: getHours(workingDays, workingHours) / 40,  
                hirer: {
                    hirerCompanyName: chatRoom.hirer.companyName,
                    hirerPostalCode: chatRoom.hirer.postalCode,
                    hirerCity: chatRoom.hirer.city,
                    hirerAddress: `${chatRoom.hirer.streetName} ${chatRoom.hirer.houseNumber}`,
                    hirerName: `${chatRoom.hirer.firstName} ${chatRoom.hirer.lastName}`,
                    key: chatRoom.hirer.key,
                    uid: ad.uid,
                },
                hourlyRate,
                hours: getHours(workingDays, workingHours),
                workingHours: `${workingHours[0]._i}-${workingHours[1]._i}`,
                lender: {
                    lenderCompanyName: chatRoom.lender.companyName,
                    lenderPostalCode: chatRoom.lender.postalCode,
                    lenderCity: chatRoom.lender.city,
                    lenderAddress: `${chatRoom.lender.streetName} ${chatRoom.lender.houseNumber}`,
                    lenderName: `${chatRoom.lender.firstName} ${chatRoom.lender.lastName}`,
                    key: chatRoom.lender.key,
                    uid: ad.uid,
                },
                startDate: period[0].format(dateFormat),
                proposalDetails: {
                    chatRoomKey: chatRoom.key,
                    senderKey: user.uid
                }
            }

            const message = {
                days: workingDays,
                hourlyRate,
                messageTime: Date.now(),
                status: 'pending',
                type: "contract",
                uid,
                workingHours: workingHours.map(time => time.format("HH:mm"))
            }
            dispatch(sendContract(chatRoom, contract, message))
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Modal
            forceRender
            centered
            title="Selecteer"
            visible={contractModalVisible}
            onCancel={() => dispatch(toggleContractModal())}
            okText="Contract aanmaken"
            confirmLoading={sendingContract}
            onOk={handleOk}
            cancelText="Annuleren">
            <span>Geef de voorwaarden aan het contract. Deze mogen afwijken van de basis gegevens in de advertentie.</span>
            <ContractForm ad={ad} form={form} chatRoom={chatRoom} />
        </Modal>
    )
}

export default CreateContractModal