import React from 'react'
import adsActions from '@/store/ads/actions'
import professions from '@/lib/helpers/professions'
import provinces from '@/lib/helpers/provinces'
import { FiltersWrapper } from './styles/Filters.styles'
import { Form, Select, Checkbox, DatePicker } from '@/components'
import { dateFormat } from '@/lib/helpers/date'
import { useDispatch, useSelector } from 'react-redux'

export const initialValues = { types: [], provinces: [], profession: undefined, period: null }
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker
const { setFilters } = adsActions
const Filters = () => {
    const dispatch = useDispatch()
    const { filters } = useSelector(state => state.Ads)
    const handleChange = (name, value) => {
        dispatch(setFilters({ ...filters, [name]: value }))
    }
    return (
        <FiltersWrapper>
            <Form
                layout="vertical"
                wrapperCol={{ xs: 24 }}>
                <Item
                    label="Type advertentie">
                    <Checkbox.Group
                        value={filters.types}
                        onChange={value => handleChange('types', value)}
                        options={[
                            { label: 'Aanbieden', value: 'offered' },
                            { label: 'Gezocht', value: 'wanted' },
                        ]} />
                </Item>
                <Item label="Functie">
                    <Select
                        value={filters.profession}
                        onChange={value => handleChange('profession', value)}
                        style={{ width: '100%' }}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {professions.map(profession => <Option key={profession} value={profession}>{profession}</Option>)}
                    </Select>
                </Item>
                <Item label="Periode">
                    <RangePicker
                        value={filters.period}
                        onChange={value => handleChange('period', value)}
                        placeholder={['Begindatum', 'Einddatum']}
                        format={dateFormat} />
                </Item>
                <Item label="Provincie(s)">
                    <Select
                        value={filters.province}
                        onChange={value => handleChange('provinces', value)}
                        allowClear
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        {Object.keys(provinces).map(province => <Option key={province} value={province}>{province}</Option>)}
                    </Select>
                </Item>
            </Form>
        </FiltersWrapper>
    )
}

export default Filters
