import styled from 'styled-components';
import { Input as AntdInput, Spin as AntdSpin } from "@/components";

const ChatRoomListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const ChatRoomListItem = styled.div`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s ease;

    .chatRoomListItemGravatar {
        width: 50px;
        margin: 0 15px 0 0;

        img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
        }
    }

    .chatRoomListItemContent {
        width: 100%;
        display: flex;
        flex-direction: column;
    
        h4 {
            font-size: 14px;
            margin: 0;
            font-weight: 500;
        }
    
        .chatExcerpt {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            p {
                margin: 0;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
            }

            .userListsTime {
                font-size: 10px;
                flex-shrink: 0;
                margin-top: auto;
            }
        }
    }
`

const ChatSiderWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(240, 240, 240);
`

const Input = styled(AntdInput)``

const SearchWrapper = styled.div`
    padding: 15px 20px;
    display: flex;
    flex: 0 0 66px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(240, 240, 240);

    ${Input} {
        padding: 0;
        border: 0;
        height: 35px;

        &:focus {
            box-shadow: none;
        }
    }
`

const Spin = styled(AntdSpin)``

const SpinWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${Spin} {
        align-self: center;
    }
`


export {
    ChatRoomListItem,
    ChatRoomListWrapper,
    ChatSiderWrapper,
    Input,
    SearchWrapper,
    Spin,
    SpinWrapper
}
