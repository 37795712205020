import React, { useState } from 'react'
import chatActions from '@/store/chat/actions'
import samplePDF from './sample.pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Button, Modal, Pagination, Popconfirm, Spin } from '@/components'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '@/context/UserProvider'

const {
    acceptContract,
    cancelContract,
    denyContract,
    togglePdfModal
} = chatActions
export const ViewPdfModal = ({ chatRoom, contract, message }) => {
    const dispatch = useDispatch()
    const { uid } = useAuth()
    const { cancelling, denying, pdfModalVisible } = useSelector(state => state.Chat)
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [success, setSuccess] = useState(false)

    const handleLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        setSuccess(true)
    }

    const handleChange = page => {
        setPageNumber(page)
    }

    const handleClose = () => {
        dispatch(togglePdfModal())
        setPageNumber(1)
    }

    const title = () => {
        switch (contract.status) {
            case 'accepted':
                return 'geaccepteerd'
            case 'cancelled':
                return 'geannuleerd'
            case 'denied':
                return 'afgewezen'
            case 'pending':
                return 'in afwachting'
            default:
                return ''
        }
    }
    return (
        <>
            <Modal
                title={`Dit contract is ${title()}`}
                className="modal-body-no-border"
                centered
                onCancel={handleClose}
                visible={pdfModalVisible}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {/* {success && <Pagination pageSize={1} current={pageNumber} total={numPages} onChange={handleChange} />} */}
                        {contract.hirer.uid !== uid ?
                            <>
                                <Popconfirm
                                    placement="topRight"
                                    title="Weet u zeker dat u het contract wilt accepteren?"
                                    onConfirm={() => dispatch(acceptContract(chatRoom, contract.key, message.key))}
                                    okText="Ja"
                                    cancelText="Nee"
                                >
                                    <Button type="primary">Accepteren</Button>
                                </Popconfirm>
                                <Popconfirm
                                    placement="topRight"
                                    title="Weet u zeker dat u het contract wilt afwijzen?"
                                    onConfirm={() => dispatch(denyContract(chatRoom, contract.key, message.key))}
                                    okText="Ja"
                                    cancelText="Nee"
                                >
                                    <Button type="dark" loading={denying} disabled={contract.status !== 'pending'}>Afwijzen</Button>
                                </Popconfirm>
                            </> :
                            <>
                                <Popconfirm
                                    placement="topRight"
                                    title="Weet u zeker dat u het contract wilt annuleren?"
                                    onConfirm={() => dispatch(cancelContract(chatRoom, contract.key, message.key))}
                                    okText="Ja"
                                    cancelText="Nee"
                                >
                                    <Button type="primary" danger loading={cancelling} disabled={contract.status !== 'pending'}>Contract annuleren</Button>
                                </Popconfirm>
                            </>
                        }
                    </div>
                }>
                <Document
                    file={samplePDF}
                    error={<div style={{ padding: '20px' }}>Er ging iets mis bij het ophalen van het contract</div>}
                    loading={<div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}><Spin /></div>}
                    onLoadSuccess={handleLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                {success && <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Pagination style={{ alignSelf: 'center', margin: '0 0 10px 0' }} pageSize={1} current={pageNumber} total={numPages} onChange={handleChange} />
                </div>}
            </Modal>
        </>
    )
}
