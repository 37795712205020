import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

export const config = {
    apiKey: process.env.REACT_APP_API_KEY || "AIzaSyB25BhDxFox2xX8bQ-goy7RZRXIfOCfjE8",
    authDomain: process.env.REACT_APP_AUTH_DOMAIN || "koppelbaas-5ace4.firebaseapp.com",
    databaseURL: process.env.REACT_APP_DATABASE_URL || "https://koppelbaas-5ace4.firebaseio.com",
    projectId: process.env.REACT_APP_PROJECT_ID || "koppelbaas-5ace4",
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET || "koppelbaas-5ace4.appspot.com",
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || "543045080588",
    appId: process.env.REACT_APP_APP_ID || "1:543045080588:web:637b5cbd7d126e8c544029",
}

firebase.initializeApp(config);

export const auth = firebase.auth()
export const db = firebase.firestore()
export const storage = firebase.storage()

export default firebase