import actions from "./actions"
import { default as email } from "../email/actions"
import { db } from "@/lib/firebase"
import { all, put, takeEvery, take } from "redux-saga/effects"
import { eventChannel, END } from 'redux-saga'
import { getRandomKey, getUser } from "@/lib/helpers/utility"

function* acceptContract({ payload }) {
    const { chatRoom, contract } = payload
    try {
        const chatRoomRef = yield db.collection("chatRooms").doc(chatRoom.key)
        const proposalRef = yield chatRoomRef.collection('proposals').doc(contract)
        yield proposalRef.update({ 'contract.status': 'accepted' })

        const contractRef = yield db.collection('contracts').doc()
        const adRef = yield db.collection('ads').doc(chatRoom.ad.key)

        const ref = db.collection('chatRooms').doc(chatRoom.key).collection('proposals').doc(contract)
        const channel = eventChannel(emit => ref.onSnapshot(emit, _ => emit(END)))
        const snapshot = yield take(channel)
        const ad = yield chatRoomRef.get()
        const adData = ad.data()
        const user = getUser()
        const receiver = (user.uid === adData.lender.key) ? adData.hirer : adData.lender
        const sender = (user.uid === adData.lender.key) ?  adData.lender : adData.hirer
        const signedContract = snapshot.data()
        contractRef.set(signedContract)

        adRef.update({ status: 'lended' })

        signedContract.contract.proposalDetails.contractKey = contractRef.id
        signedContract.contract.receiver = receiver
        signedContract.contract.sender = sender
        signedContract.contract.adTitle = adData.ad.title
        yield fetch(`https://koppelbaas.herokuapp.com/contracts/?signed=true`, {
            method: 'POST',
            body: JSON.stringify(signedContract.contract),
            headers: { 'Content-Type': 'application/json' }
        })
        yield put(actions.acceptContractSuccess())
    } catch (error) {
        console.log(error)
    }
}

function* addMessage({ payload }) {
    const { chatRoom, data } = payload
    const message = {
        type: 'message',
        ...data,
        messageTime: Date.now()
    }

    try {
        const chatRoomRef = db.collection("chatRooms")
            .doc(chatRoom.key)
        const document = yield chatRoomRef.get()

        if (!document.data()) {
            const chatRoomRef = db.collection("chatRooms").doc(chatRoom.key)
            yield chatRoomRef.set(chatRoom)
            yield chatRoomRef.update({ key: chatRoom.key })
        }

        yield chatRoomRef.set({ message }, { merge: true })
        const ad = yield chatRoomRef.get()
        const adData = ad.data()
        const user = getUser()

        const receiver = adData.hirer.key === user.uid ? adData.lender : adData.hirer
        const sender = adData.hirer.key === user.uid ? adData.hirer : adData.lender
        message["sender"] = { key: user.uid }

        if (sender.profileImageUrl) {
            message["profileImageUrl"] = sender.profileImageUrl
        }

        const messageRef = yield chatRoomRef
                .collection("messages")
                .doc()

        const emailData = {
            to: [receiver.email],
            template: {
                name: 'message-received',
                data: {
                    chat_link: `${window.location.origin}/chat/${adData.key}`
                }
            }
        }

        yield messageRef.set(message)
        yield put(actions.addMessageSuccess(chatRoom, message, data))
        yield put(email.sendEmail(emailData))
    } catch (error) {
        yield put(actions.addMessageError(error))
    }
}

function* cancelContract({ payload }) {
    const { chatRoom, contract } = payload
    try {

        const chatRoomRef = yield db.collection("chatRooms").doc(chatRoom.key)
        const proposalsRef = yield chatRoomRef.collection('proposals').doc(contract)

        const ad = yield chatRoomRef.get()
        const adData = ad.data()

        const user = getUser()
        const receiver = adData.hirer.key === user.uid ? adData.lender : adData.hirer
        const emailData = {
            to: [receiver.email],
            template: {
                name: 'contract-cancelled',
                data: {
                    chat_link: `${window.location.origin}/chat/${adData.key}`
                }
            }
        }

        yield proposalsRef.update({ 'contract.status': 'cancelled' })
        yield put(email.sendEmail(emailData))
        yield put(actions.cancelContractSuccess())
    } catch (error) {
        yield put(actions.cancelContractError(error))
    }
}

function* createChatRoom({ payload }) {
    const { ad, uid } = payload

    const chatRoomsRef = db.collection("chatRooms")
    const adSnapshot = yield chatRoomsRef
        .where(`contractors`, `array-contains`, uid)
        .where(`ad.key`, "==", ad)
        .get()

    const chatForAdExists = adSnapshot.docs
        .filter(doc => doc.data().ad.key === ad).length > 0

    try {
        let chatRoom
        if (!chatForAdExists) {
            const adRef = db.collection("ads").doc(ad)
            const adDoc = yield adRef.get()
            const adData = adDoc.data()

            const userRef = db.collection("contractors").doc(uid)
            const userDoc = yield userRef.get()
            const userData = userDoc.data()

            const lender = adData["lender"]

            const allowedAdData = ['period', 'type', 'uid', 'profession', 'title', 'key']
            const filteredAdData = Object.keys(adData)
                .filter(key => allowedAdData.includes(key))
                .reduce((obj, key) => {
                    obj[key] = adData[key]
                    return obj
                }, {})

            if (adData.employee) {
                filteredAdData["employee"] = {
                    professions: adData.employee.professions,
                    streetName: adData.employee.streetName,
                    houseNumber: adData.employee.houseNumber,
                    city: adData.employee.city,
                    postalCode: adData.employee.postalCode,
                    address: '',
                    firstName: adData.employee.firstName,
                    lastName: adData.employee.lastName,
                    dateOfBirth: adData.employee.dateOfBirth,
                    hourlyRate: adData.employee.hourlyRate,
                }
            }

            let data = adData.type === "wanted" ? {
                ad: filteredAdData,
                hirer: lender,
                lender: userData
            } : {
                ad: filteredAdData,
                hirer: userData,
                lender
            }
            data['contractors'] = [lender.key, userData.key]
            data.key = getRandomKey()
            chatRoom = data
        } else {
            chatRoom = adSnapshot.docs
                .filter(doc => (doc.data().ad.key === ad && (doc.data().lender.key === uid || doc.data().hirer.key === uid)))[0].data()
        }
        yield put(actions.createChatRoomSuccess(chatRoom))
    } catch (error) {
        yield put(actions.createChatRoomError(error))
    }
}

function* denyContract({ payload }) {
    const { chatRoom, contract } = payload
    try {
        const chatRoomRef = yield db.collection("chatRooms").doc(chatRoom.key)
        const proposalRef = yield chatRoomRef.collection('proposals').doc(contract)
        yield proposalRef.update({ 'contract.status': 'denied' })
        const ad = yield chatRoomRef.get()
        const adData = ad.data()
        const user = getUser()
        const receiver = adData.hirer.key === user.uid ? adData.lender : adData.hirer
        const emailData = {
            to: [receiver.email],
            template: {
                name: 'contract-turned-down',
                data: {
                    chat_link: `${window.location.origin}/chat/${adData.key}`,
                    ad_title: adData.ad.title,
                    company_name: receiver.companyName
                }
            }
        }
        yield put(email.sendEmail(emailData))
        yield put(actions.denyContractSuccess())
    } catch (error) {
    }
}

function* getChatRooms({ payload }) {
    const { uid } = payload

    const ref = db.collection("chatRooms")
        .where(`contractors`, `array-contains`, uid)

    const channel = eventChannel(emit => ref.onSnapshot(emit, ({ message }) => {
        emit(END)
    }))

    try {
        while (true) {
            const snapshot = yield take(channel)

            const hirerChatRooms = snapshot.docs.reduce((chatRooms, chatRoomDoc) => {
                chatRooms[chatRoomDoc.id] = { ...chatRoomDoc.data() }
                return chatRooms
            }, {})

            // Filter removed for now, otherwise proposals don't get fetched when a chat message is sent
            // const addedDocs = snapshot.docChanges().filter(change => change.type === 'added')
            const addedDocs = snapshot.docChanges()

            yield all(addedDocs.map(change =>
                put(actions.getMessages(change.doc.id))
            ))
            yield all(addedDocs.map(change =>
                put(actions.getProposals(change.doc.id))
            ))

            yield put(actions.getChatRoomsSuccess(hirerChatRooms))
        }
    } catch (error) {
        yield put(actions.getChatRoomsError(error))
    }
}

function* getMessages({ payload }) {
    const { chatRoom } = payload
    const messagesRef = db.collection("chatRooms")
        .doc(chatRoom)
        .collection('messages')
        .orderBy('messageTime')

    const messagesChannel = eventChannel(emit => messagesRef.onSnapshot(emit, _ => emit(END)))
    try {
        while (true) {
            const messagesSnapshot = yield take(messagesChannel)
            const messages = messagesSnapshot.docs.reduce((messages, messageDoc) => {
                messages[messageDoc.id] = { key: messageDoc.id, ...messageDoc.data() }
                return messages
            }, {})

            yield put(actions.getMessagesSuccess(chatRoom, messages))
        }
    } catch ({ message }) {
        yield put(actions.getMessagesError(message))
    }
}

function* getProposals({ payload }) {
    const { chatRoom } = payload
    const proposalsRef = db.collection("chatRooms")
        .doc(chatRoom)
        .collection('proposals')
        .orderBy('messageTime')

    const proposalsChannel = eventChannel(emit => proposalsRef.onSnapshot(emit, _ => emit(END)))

    try {
        while (true) {
            const proposalsSnapshot = yield take(proposalsChannel)
            const proposals = proposalsSnapshot.docs.reduce((proposals, proposalDoc) => {
                proposals[proposalDoc.id] = { key: proposalDoc.id, ...proposalDoc.data() }
                return proposals
            }, {})

            yield put(actions.getProposalsSuccess(chatRoom, proposals))
        }
    } catch ({ message }) {
        yield put(actions.getProposalsError(message))
    }
}

function* sendContract({ payload }) {
    const { chatRoom, contract } = payload

    try {
        const chatRoomRef = db.collection("chatRooms").doc(chatRoom.key)
        const document = yield chatRoomRef.get()

        if (!document.data()) {
            const chatRoomRef = db.collection("chatRooms").doc(chatRoom.key)
            yield chatRoomRef.set(chatRoom)
            yield chatRoomRef.update({ key: chatRoom.key })
        }

        const proposalRef = yield chatRoomRef.collection("proposals").doc()

        const data = {
            sender: {
                key: contract.proposalDetails.senderKey
            },
            messageTime: Date.now(),
            contract: contract,
        }

        data.contract.proposalDetails.proposalKey = proposalRef.id
        data.contract.status = 'pending'
        data.contractors = chatRoom.contractors
        yield proposalRef.set(data)
        const ad = yield chatRoomRef.get()
        const adData = ad.data()
        const user = getUser()
        const receiver = adData.hirer.key === user.uid ? adData.lender : adData.hirer
        const emailData = {
            to: [receiver.email],
            template: {
                name: 'contract-created',
                data: {
                    chat_link: `${window.location.origin}/chat/${adData.key}`,
                    ad_title: adData.ad.title,
                    company_name: receiver.companyName
                }
            }
        }
        yield fetch(`https://koppelbaas.herokuapp.com/contracts/?hiddenInfo=true`, {
            method: 'POST',
            body: JSON.stringify(contract),
            headers: { 'Content-Type': 'application/json' }
        })
        yield put(actions.sendContractSuccess())
        yield put(email.sendEmail(emailData))
        return

    } catch (error) {
        yield put(actions.sendContractError(error))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.ACCEPT_CONTRACT, acceptContract),
        takeEvery(actions.ADD_MESSAGE, addMessage),
        takeEvery(actions.CANCEL_CONTRACT, cancelContract),
        takeEvery(actions.CREATE_CHAT_ROOM, createChatRoom),
        takeEvery(actions.DENY_CONTRACT, denyContract),
        takeEvery(actions.GET_CHAT_ROOMS, getChatRooms),
        takeEvery(actions.GET_MESSAGES, getMessages),
        takeEvery(actions.SEND_CONTRACT, sendContract),
        takeEvery(actions.GET_PROPOSALS, getProposals)
    ]);
}
