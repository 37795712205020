const ACTIONS = 'EMAILS_'

const actions = {
    SEND_EMAIL: ACTIONS + 'SEND_EMAIL',
    SEND_EMAIL_ERROR: ACTIONS + 'SEND_EMAIL_ERROR',
    SEND_EMAIL_SUCCESS: ACTIONS + 'SEND_EMAIL_SUCCESS',

    sendEmail: data => ({ type: actions.SEND_EMAIL, payload: { data }, }),
    sendEmailError: error => ({ type: actions.SEND_EMAIL_ERROR, payload: { error }, }),
    sendEmailSuccess: () => ({ type: actions.SEND_EMAIL_SUCCESS, })
}

export default actions