import actions from './actions'

const intialState = {
    saving: false,
    contractor: {},
    feedback: {},
    fileList: [],
    error: "",
}

const reducer = (state = intialState, action) => {
    const { type, payload } = action
    switch (type) {
        case actions.DOWNLOAD_URL: {
            return {
                ...state,
                fileList: [{
                    uid: 1,
                    status: 'done',
                    url: payload.url
                }]
            }
        }
        case actions.GET_CONTRACTOR_ERROR:
            return {
                ...state,
                error: payload.error,
            }
        case actions.GET_CONTRACTOR_SUCCESS:
            return {
                ...state,
                contractor: payload.contractor,
            }
        case actions.REMOVE_FEEDBACK:
            return {
                ...state,
                feedback: {},
            }
        case actions.REMOVE_CONTRACTOR:
            return {
                ...state,
                contractor: {},
            }
        case actions.SAVE_CONTRACTOR:
            return {
                ...state,
                saving: true,
            }
        case actions.SAVE_CONTRACTOR_ERROR:
            return {
                ...state,
                saving: false,
                feedback: payload.feedback,
            }
        case actions.SAVE_CONTRACTOR_SUCCESS:
            return {
                ...state,
                saving: false,
                feedback: payload.feedback,
            }
        case actions.UPLOAD_ERROR: {
            return {
                ...state,
                fileList: [{
                    uid: 1,
                    status: 'error'
                }]
            }
        }
        case actions.UPLOAD_START: {
            return {
                ...state,
                onUploadStart: true,
                uploadProgress: payload.uploadProgress,
                fileList: [{
                    uid: 1,
                    percent: payload.uploadProgress,
                    status: 'uploading',
                    url: payload.url
                }]

            }
        }
        default:
            return state
    }
}

export default reducer
