import React, { useEffect } from 'react';
import moment from 'moment';
import useWindowDimensions from '@/lib/helpers/useWindowDimensions';
import { Alert } from '@/components';
import { ChatMessagesWrapper } from "./styles/ChatMessages.styles";
import { dateFormat } from '@/lib/helpers/date';
import { useLocation } from 'react-router';
import { TextMessage } from '@/containers/Chat';
import { ContractMessage } from './ContractMessage';

const isExpired = endDate => {
    return moment(endDate, dateFormat).isBefore(moment(moment().format(dateFormat), dateFormat))
}

export const ChatMessages = ({ ad, messages, chatRoom }) => {

    const { period } = ad
    const { height } = useWindowDimensions()

    const location = useLocation()
    const scrollToBottom = () => {
        const messageChat = document.getElementById('messageChat')
        messageChat.scrollTop = messageChat.scrollHeight
    }

    useEffect(() => {
        scrollToBottom()
    }, [location, messages])

    return (
        <ChatMessagesWrapper style={{ flex: `0 0 ${height - 290}px` }} id="messageChat">
            {messages.map(message => {
                const { sender, messageTime, text, contract, pdfStatus, pdfLocation } = message[1]
                const key = message[0]
                return React.createElement(text ? TextMessage : ContractMessage, {
                    key, sender, messageTime, text, contract, pdfStatus, pdfLocation, chatRoom
                })
            })
            }

            {isExpired(period[1]) && <Alert style={{ margin: '20px' }} message="Deze advertentie is verlopen. U kunt niet meer chatten" type="error" />}

        </ChatMessagesWrapper>
    )
}
