import styled from 'styled-components'
import { Row as AntdRow } from '@/components'

const Row = styled(AntdRow)`
    width: 100%;
`

const SpinWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export {
    Row,
    SpinWrapper
}
