import actions from './actions'
import _ from 'lodash'

const initState = {
    accepting: false,
    ad: {},
    cancelling: false,
    chatRoom: "",
    chatRooms: {},
    contract: undefined,
    contractModalVisible: false,
    denying: false,
    error: "",
    chatRoomsLoaded: false,
    loading: true,
    sendingContract: false,
    messages: {}
}

export default function reducer(state = initState, action) {
    const { type, payload } = action
    switch (type) {
        case actions.ACCEPT_CONTRACT:
            return {
                ...state,
                accepting: true
            }
        case actions.ACCEPT_CONTRACT_SUCCESS:
            return {
                ...state,
                accepting: false,
                pdfModalVisible: false,
            }
        case actions.ADD_MESSAGE_ERROR:
            return {
                ...state,
                error: payload.error
            }
        case actions.CANCEL_CONTRACT:
            return {
                ...state,
                cancelling: true
            }
        case actions.CANCEL_CONTRACT_SUCCESS:
            return {
                ...state,
                cancelling: false,
                pdfModalVisible: false,
            }
        case actions.CREATE_CHAT_ROOM_ERROR:
            return {
                ...state,
                error: payload.error
            }
        case actions.CREATE_CHAT_ROOM_SUCCESS:
            return {
                ...state,
                chatRoom: payload.chatRoom.key,
                chatRooms: {...state.chatRooms, [payload.chatRoom.key]: payload.chatRoom}
            }
        case actions.DENY_CONTRACT:
            return {
                ...state,
                denying: true
            }
        case actions.DENY_CONTRACT_SUCCESS:
            return {
                ...state,
                denying: false,
                pdfModalVisible: false,
            }
        case actions.GET_CHAT_ROOMS:
            return {
                ...state,
            }
        case actions.GET_CHAT_ROOMS_SUCCESS:
            return {
                ...state,
                chatRoomsLoaded: true,
                chatRooms: _.merge(state.chatRooms, payload.chatRooms)
            }
        case actions.GET_MESSAGES_ERROR:
            return {
                ...state,
                loading: false,
                messagesLoaded: true,
                error: payload.error
            }
        case actions.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                chatRooms: {
                    ...state.chatRooms,
                    [payload.chatRoom]: {
                        ...state.chatRooms[payload.chatRoom],
                        messages: payload.messages
                    }
                },
                loading: false,
                error: ''
            }
        case actions.GET_PROPOSALS_SUCCESS:
            return {
                ...state,
                chatRooms: {
                    ...state.chatRooms,
                    [payload.chatRoom]: {
                        ...state.chatRooms[payload.chatRoom],
                        proposals: payload.proposals
                    }
                },
                loading: false,
                error: ''
            }
        case actions.GET_PROPOSALS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload.error
            }
        case actions.REMOVE_CHAT_ROOM:
            return {
                ...state,
                chatRoom: "",
            }
        case actions.SEND_CONTRACT:
            return {
                ...state,
                sendingContract: true,
            }
        case actions.SEND_CONTRACT_SUCCESS:
            return {
                ...state,
                contractModalVisible: false,
                sendingContract: false,
            }
        case actions.TOGGLE_CONTRACT_MODAL:
            return {
                ...state,
                contractModalVisible: !state.contractModalVisible
            }
        default:
            return state
    }
}
