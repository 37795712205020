import React, { useEffect } from 'react';
import chatActions from '@/store/chat/actions'
import { Button, Modal } from '@/components';
import { FileTextOutlined, CloudDownloadOutlined, LoadingOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SingleMessage } from "./styles/ChatMessages.styles";
import { timeDifference } from '@/lib/helpers/utility';
import { useDispatch } from 'react-redux';
import { useAuth } from '@/context/UserProvider';
import { storage } from "@/lib/firebase"
import { Tooltip } from 'antd';
const { cancelContract, acceptContract, denyContract } = chatActions

export const ContractMessage = ({ sender, messageTime, contract, pdfStatus, pdfLocation, chatRoom }) => {
    const dispatch = useDispatch()
    const { status, hourlyRate } = contract
    const { uid } = useAuth()
    const { confirm } = Modal;
    const isUser = sender.key === uid
    const src = sender.profileImageUrl || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'

    useEffect(() => {
        if (pdfStatus === 'completed') {
            storage.ref(`contracts/${pdfLocation}.pdf`).getDownloadURL().then((url) => {
                var link = document.getElementById(contract.proposalDetails.proposalKey)
                link.setAttribute('href', url)
            })
        }
    }, [pdfLocation, pdfStatus, contract.proposalDetails.proposalKey])

    const showConfirm = (title, callback) => {
        confirm({
            title,
            icon: <ExclamationCircleOutlined />,
            content: 'Weet u het zeker?',
            onOk() {
                callback()
            }
        })
    }

    const cancelProposal = () => {
        dispatch(cancelContract(chatRoom, contract.proposalDetails.proposalKey))
    }

    const denyProposal = () => {
        dispatch(denyContract(chatRoom, contract.proposalDetails.proposalKey))
    }

    const acceptProposal = () => {
        dispatch(acceptContract(chatRoom, contract.proposalDetails.proposalKey))
    }

    function formatWorkingDays(workingDays) {
        if (workingDays) {
            return workingDays.join(", ")
        }
    }

    return (
        <>
            {isUser ?
                <SingleMessage className="loggedUser">
                    <div className="messageContent isUser">
                        <div className="messageContentText" style={{
                            borderRadius: '3px 0 0 0',
                            width: '220px',
                            opacity: status !== 'pending' && status !== 'accepted' && '.5'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    {pdfStatus === 'generating' && <p>Wordt gegenereerd</p>}
                                    {pdfStatus === 'error' && <p>Er is iets mis gegaan</p>}
                                    {status === 'accepted' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>Geaccepteerd</p>}
                                    {status === 'cancelled' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>Geannuleerd</p>}
                                    {status === 'denied' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>Afgewezen</p>}
                                    {status === 'pending' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>In afwachting</p>}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <p>Dagen: {formatWorkingDays(contract.workingDays)}</p>
                                        <p>Uren: {contract.hours}</p>
                                        <p>€{hourlyRate} per uur</p>
                                    </div>
                                    <div>
                                        <FileTextOutlined style={{ fontSize: '45px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '220px' }}>
                            {status === 'pending' && pdfStatus !== 'error' &&
                                <Button style={{ borderRadius: '0 0 3px 3px', width: '50%', backgroundColor: 'lightcoral' }} onClick={() => showConfirm('Annuleer het voorstel', cancelProposal)}>
                                    <Tooltip title="Annuleer">
                                        <CloseOutlined />
                                    </Tooltip>
                                </Button>
                            }
                            {pdfStatus !== 'error' &&
                                <Button id={contract.proposalDetails.proposalKey} style={{ borderRadius: '0 0 3px 3px', width: status === 'pending' ? '50%' : '100%' }} href='' target="_blank">
                                    {pdfStatus === 'generating'
                                        ? <LoadingOutlined />
                                        : <Tooltip title="Download">
                                            <CloudDownloadOutlined />
                                        </Tooltip>
                                    }
                                </Button>
                            }
                        </div>
                        <div className="messageTime">
                            <p>{timeDifference(messageTime)}</p>
                        </div>
                    </div>
                    <div className="messageGravatar">
                        <img
                            alt="#"
                            src={src}
                        />
                    </div>
                </SingleMessage> :
                <SingleMessage>
                    <div className="messageGravatar">
                        <img
                            alt="#"
                            src={src}
                        />
                    </div>
                    <div className="messageContent notUser">
                        <div className="messageContentText" style={{
                            borderRadius: '0 3px 0 0',
                            width: '220px',
                            opacity: status !== 'pending' && status !== 'accepted' && '.5'
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    {pdfStatus === 'generating' && <p>Wordt gegenereerd</p>}
                                    {pdfStatus === 'error' && <p>Er is iets mis gegaan</p>}
                                    {status === 'accepted' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>Geaccepteerd</p>}
                                    {status === 'cancelled' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>Geannuleerd</p>}
                                    {status === 'denied' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>Afgewezen</p>}
                                    {status === 'pending' && pdfStatus !== 'generating' && pdfStatus !== 'error' && <p>In afwachting</p>}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <p>Dagen: {formatWorkingDays(contract.workingDays)}</p>
                                        <p>Tijden: {contract.hours}</p>
                                        <p>€{contract.hourlyRate} per uur</p>
                                    </div>
                                    <div>
                                        <FileTextOutlined style={{ fontSize: '45px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '220px' }}>
                            {status === 'pending' && pdfStatus !== 'error' &&
                                <>
                                    <Button style={{ borderRadius: '0 0 3px 3px', width: '50%', backgroundColor: 'lightgreen' }} onClick={() => showConfirm('Accepteer het voorstel', acceptProposal)}>
                                        <Tooltip title="Accepteer">
                                            <CheckOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button style={{ borderRadius: '0 0 3px 3px', width: '50%', backgroundColor: 'lightcoral' }} onClick={() => showConfirm('Wijs het voorstel af', denyProposal)}>
                                        <Tooltip title="Wijs af">
                                            <CloseOutlined />
                                        </Tooltip>
                                    </Button>
                                </>
                            }

                            {pdfStatus !== 'error' &&
                                <Button id={contract.proposalDetails.proposalKey} style={{ borderRadius: '0 0 3px 3px', width: '100%' }} href='' target="_blank">
                                    {pdfStatus === 'generating'
                                        ? <LoadingOutlined />
                                        : <Tooltip title="Download">
                                            <CloudDownloadOutlined />
                                        </Tooltip>
                                    }
                                </Button>
                            }
                        </div>
                        <div className="messageTime">
                            <p>{timeDifference(messageTime)}</p>
                        </div>
                    </div>
                </SingleMessage>
            }
        </>
    )
}