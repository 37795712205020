import ImgCrop from 'antd-img-crop';
import React, { useState, useEffect } from 'react'
import contractorActions from '@/store/contractor/actions'
import { Button, Upload, Form, Input, InputNumber, message, Checkbox } from '@/components'
import { DetailsFormWrapper } from './styles/DetailsForm.styles'
import { UploadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@/context/UserProvider';

const {
    saveContractor,
    removeFeedback,
    upload,
    downloadURL,
} = contractorActions
const DetailsForm = () => {
    const dispatch = useDispatch()
    const { email } = useAuth()
    const { contractor, saving, feedback, fileList } = useSelector(state => state.Contractor)
    const [form] = Form.useForm()
    const [changeCurrentPassword, setChangeCurrentPassword] = useState(false)

    useEffect(() => {
        form.setFieldsValue({ ...contractor, email })
        if (contractor?.profileImageUrl) dispatch(downloadURL(contractor.profileImageUrl))
    }, [dispatch, email, form, contractor])

    useEffect(() => {
        if (feedback.type && feedback.content) {
            message[feedback.type](feedback.content)
            dispatch(removeFeedback())
        }
    }, [dispatch, feedback])

    const handleFinish = async values => {
        Object.keys(values).forEach(value => {
            if (!values[value]) {
                delete values[value]
            }
        })
        dispatch(saveContractor(values))
    }

    return (
        <DetailsFormWrapper>
            {/* <RouteLeavingGuard
                when={true}
                navigate={path => history.push(path)}
                shouldBlockNavigation={() => JSON.stringify(form.getFieldsValue(true)) !== JSON.stringify({ ...contractor, email })}
                okText="Ja"
                cancelText="Nee">
                U heeft niet-opgeslagen wijzigingen. Wilt u uw wijzigen ongedaan maken?
            </RouteLeavingGuard> */}
            <Form
                form={form}
                colon={false}
                labelCol={{ xs: 8 }}
                wrapperCol={{ xs: 16 }}
                layout="horizontal"
                labelAlign="left"
                requiredMark={false}
                initialValues={contractor}
                onFinish={handleFinish}>
                <Form.Item
                    name="companyName"
                    label="Bedrijfsnaam"
                    rules={[
                        {
                            required: true,
                            message: 'Vul uw bedrijfsnaam in',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="kvkNumber"
                    label="KVK-nummer"
                    rules={[
                        {
                            required: true,
                            message: 'Vul uw KVK-Nummer in',
                        },
                        {
                            len: 8,
                            message: 'Vul een geldig KVK-Nummer in'
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="postalCode"
                    label="Postcode"
                    rules={[
                        {
                            required: true,
                            message: 'Vul uw postcode in',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="city"
                    label="Stad"
                    rules={[
                        {
                            required: true,
                            message: 'Vul uw plaatsnaam in',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="streetName"
                    label="Straatnaam"
                    rules={[
                        {
                            required: true,
                            message: 'Vul uw straatnaam in',
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="houseNumber"
                    label="Huisnummer"
                    rules={[{ required: true, message: 'Vul uw huisnummer in', },]}>
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="addition"
                    label="Toevoeging">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="firstName"
                    label="Voornaam"
                    rules={[{ required: true, message: 'Vul uw voornaam in', },]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    label="Achternaam"
                    rules={[{ required: true, message: 'Vul uw achternaam in', },]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Profielfoto">
                    <ImgCrop modalOk={"Toepassen"} modalCancel={"Annuleren"}>
                        <Upload
                            name="logo"
                            fileList={fileList}
                            showUploadList={{ showRemoveIcon: false }}
                            onPreview={() => { }}
                            maxCount={1}
                            beforeUpload={file => { dispatch(upload(file)); return false }}
                            listType="picture"
                            className="upload-list-inline">
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    label="Telefoonnummer"
                    rules={[{ required: true, message: 'Vul uw telefoonnummer in', },]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="E-mailadres"
                    rules={[
                        { required: true, message: 'Vul uw e-mailadres in', },
                        { type: 'email', message: 'Vul een geldig e-mailadres in', },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Huidige wachtwoord"
                    help="Vul uw huidige wachtwoord in als u uw e-mailadres of wachtwoord wilt wijzigen"
                    rules={[
                        { required: changeCurrentPassword, message: "Vul uw huidige wachtwoord in" }
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Wilt u uw wachtwoord wijzigen?"
                    labelCol={{ md: { offset: 8, span: 16 } }}
                    wrapperCol={{ md: { offset: 8, span: 16 } }}>
                    <Checkbox onChange={e => setChangeCurrentPassword(e.target.checked)}>
                        Ja
                    </Checkbox>
                </Form.Item>
                {changeCurrentPassword && <>
                    <Form.Item
                        label="Nieuw wachtwoord"
                        name="newPassword"
                        rules={[
                            { required: changeCurrentPassword, message: "Vul uw nieuwe wachtwoord in" }
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Wachtwoord bevestigen"
                        name="confirmPassword"
                        rules={[
                            { required: changeCurrentPassword, message: "Herhaal uw nieuwe wachtwoord" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Wachtwoorden komen niet overeen'));
                                },
                            }),
                        ]}>
                        <Input.Password />
                    </Form.Item>
                </>}
                <Form.Item wrapperCol={{ md: { offset: 8, span: 16 } }}>
                    <Button
                        loading={saving}
                        type="primary"
                        htmlType="submit">
                        Opslaan
                        </Button>
                </Form.Item>
            </Form>
        </DetailsFormWrapper>
    )
}

export default DetailsForm