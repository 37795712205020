import React, { useEffect, useState } from 'react'
import useWindowDimensions from '@/lib/helpers/useWindowDimensions';
import { ChatRoomListWrapper } from '@/containers/Chat/styles/ChatSider.styles'
import { Collapse } from '@/components'
import { ChatRoomListItem } from '@/containers/Chat'
import { useAuth } from '@/context/UserProvider'
import _ from 'lodash'
import moment from 'moment';
import { getLatestMessageFromChatroom } from '@/lib/helpers/utility';

const { Panel } = Collapse

export const ChatRoomList = ({ chatRooms = [] }) => {
    const { uid } = useAuth()
    
    const [loaded, setLoaded] = useState(false);
    const { height } = useWindowDimensions()
    
    let hiringChatRooms = []
    let lendingChatRooms = []

    useEffect(() => {
        setLoaded(chatRooms.reduce((loaded, { ad }) => loaded && (ad ? true : false), true))
    }, [chatRooms])

    if (loaded) {
        hiringChatRooms = chatRooms.filter(chatRoom => chatRoom.hirer.key === uid)
        lendingChatRooms = chatRooms.filter(chatRoom => chatRoom.lender.key === uid)
        lendingChatRooms = _.orderBy(lendingChatRooms, (o) => {
            return moment(getLatestMessageFromChatroom(o)?.messageTime)
        }, ['desc'])
        hiringChatRooms = _.orderBy(hiringChatRooms, (o) => {
            return moment(getLatestMessageFromChatroom(o)?.messageTime)
        }, ['desc'])
    }

    return (
        <Collapse bordered={false} defaultActiveKey={['1', '2']} style={{ flex: `0 0 ${height - 224}px`, overflowY: 'auto', '&::WebkitScrollbar': {display: 'none'} }} ghost>
            {hiringChatRooms.length > 0 &&
                <Panel header="Inlenen" key="1">
                    <ChatRoomListWrapper>
                        {hiringChatRooms.map(chatRoom => <ChatRoomListItem key={chatRoom.key} otherContractor={chatRoom.lender} chatRoom={chatRoom} />)}
                    </ChatRoomListWrapper>
                </Panel>}
            {lendingChatRooms.length > 0 &&
                <Panel header="Uitlenen" key="2">
                    <ChatRoomListWrapper>
                        {lendingChatRooms.map(chatRoom => <ChatRoomListItem key={chatRoom.key} otherContractor={chatRoom.hirer} chatRoom={chatRoom} />)}
                    </ChatRoomListWrapper>
                </Panel>}
        </Collapse>
    )
}
