import React, { useCallback, useEffect, useRef } from 'react'
import appActions from '@/store/app/actions';
import { AppHolder } from "./styles/DashboardLayout.styles"
import { DashboardSider } from "./DashboardSider"
import { useDispatch, useSelector } from "react-redux"
import { Grid } from '@/components'
import { Layout } from "@/components"

const { Footer } = Layout
const { toggleCollapsed } = appActions

export const DashboardLayout = ({ children }) => {
    const { collapsed } = useSelector(state => state.App)
    const ref = useRef()
    const screens = Grid.useBreakpoint()
    const dispatch = useDispatch()

    useEffect(() => {
        if (screens['md']) {
            dispatch(toggleCollapsed(false))
        }
    }, [dispatch, screens])

    const clickListener = useCallback(
        e => {
            if (ref.current && ref.current.contains(e.target) && !screens['md']) {
                dispatch(toggleCollapsed(true))
            }
        },
        [dispatch, ref, screens]
    )

    useEffect(() => {
        document.addEventListener('click', clickListener)
        return () => {
            document.removeEventListener('click', clickListener)
        }
    }, [clickListener])

    return (
        <AppHolder>
            <Layout style={{
                minHeight: '100vh',
                overflow: 'hidden',
            }}>
                <DashboardSider />
                <div style={{ display: 'flex', width: '100%', marginLeft: `${collapsed ? '' : '200px'}`}} ref={ref}>
                    <Layout>
                        {children}
                        <Footer style={{ textAlign: 'center' }}>Koppelbaas ©2021 | <a
                            href="https://storage.googleapis.com/koppelbaas-accept.appspot.com/gebruikersovereenkomst.pdf"
                            target="_blank"
                            rel="noreferrer">Gebruikersovereenkomst</a></Footer>
                    </Layout>
                </div>
            </Layout>
        </AppHolder>
    )
}
