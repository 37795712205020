import React, { useEffect } from 'react'
import employeesActions from '@/store/employees/actions'
import { Col, Spin } from '@/components'
import { Row, SpinWrapper } from '@/pages/styles/workforce.styles'
import { WorkforceSider, WorkforceContent, WorkforceLayout } from "@/containers/Workforce";
import { useDispatch, useSelector } from 'react-redux';
import useDocumentTitle from '@/lib/helpers/appTitle';

const { getEmployees } = employeesActions

export const EditEmployeePage = ({ match }) => {
    useDocumentTitle('Koppelbaas - Bewerk personeel')
    const dispatch = useDispatch()
    const { employee } = match.params
    const { employees, loaded } = useSelector(state => state.Employees)

    useEffect(() => {
        if (!loaded) {
            dispatch(getEmployees())
        }
    }, [dispatch, loaded])

    return (
        <WorkforceLayout>
            {!loaded ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <Row>
                    <Col {...(employee ? { xs: 0, md: 8, lg: 6 } : { xs: 24, md: 8, lg: 6 })}>
                        <WorkforceSider active={employee} />
                    </Col>
                    <Col {...(employee ? { xs: 24, md: 16, lg: 18 } : { xs: 0, md: 16, lg: 18 })}>
                        <WorkforceContent employee={employees[employee]} />
                    </Col>
                </Row>}
        </WorkforceLayout >
    )
}
