import React from 'react'
import { ContentHeader, EmployeeForm } from '@/containers/Workforce';
import { WorkforceContentWrapper } from '@/containers/Workforce/styles/WorkforceContent.styles'

export const WorkforceContent = ({ employee }) => {
    return (
        <WorkforceContentWrapper>
            <ContentHeader title={employee ? `${employee['firstName']} ${employee['lastName']}` : "Nieuw personeel"} />
            <EmployeeForm employee={employee} />
        </WorkforceContentWrapper>
    )
}
