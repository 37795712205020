import React, { useEffect, useState } from 'react'
import chatActions from '@/store/chat/actions'
import provinces from '@/lib/helpers/provinces';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { timeDifference } from "@/lib/helpers/utility";
import { useAuth } from '@/context/UserProvider';
import { Space, Button, List, Tag, Badge } from "@/components";
import { CalendarOutlined, ProfileOutlined, BankOutlined } from "@ant-design/icons";

const {
    createChatRoom,
} = chatActions

const Document = ({ ad }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { ads } = useSelector(state => state.Ads)
    const { chatRoom } = useSelector(state => state.Chat)
    const { createdAt, title, employee, period, age, description, type } = ads[ad]
    const { uid } = useAuth()
    const [creating, setCreating] = useState(false)
    const actions = [
        <Space>
            <CalendarOutlined /><span>Van {period[0]} tot {period[1]}</span>
        </Space>
    ]

    console.log(ads)

    useEffect(() => {
        if (chatRoom) {
            history.push(`/chat/${chatRoom}`)
        }
    }, [chatRoom, history])

    if (employee) {
        actions.push(
            <Space>
                <BankOutlined /><span>€{employee.hourlyRate} per uur</span>
            </Space>
        )
    } else {
        actions.push(
            <Space>
                <ProfileOutlined /><span>Leeftijd tussen {age[0]} en {age[1]} jaar</span>
            </Space>
        )
    }
    return (
        <Badge.Ribbon text={type === "wanted" ? "Gezocht" : ''} style={{ width: 95, textAlign: 'center', marginTop: 16, display: type === 'offered' ? 'none' : 'block' }} color={type === "wanted" ? "#81c0e0" : ""}>
            <List.Item
                style={{ flexWrap: 'wrap' }}
                key={ad}
                extra={
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        {`${timeDifference(createdAt)} geplaatst`}
                        <div>
                            {(ads[ad].lender?.phoneNumber || ads[ad].hirer?.phoneNumber) && <Button
                                type="default"
                                style={{ marginRight: 10 }}
                                loading={creating}
                                href={`tel:${ads[ad].lender?.phoneNumber || ads[ad].hirer?.phoneNumber}`}>Bel
                            </Button>}
                            <Button
                                type="primary"
                                loading={creating}
                                onClick={() => {
                                    setCreating(true)
                                    dispatch(createChatRoom(ad, uid))
                                }}>Chat
                            </Button>
                        </div>
                    </Space>
                }
                actions={actions}>
                <List.Item.Meta
                    title={
                        <div style={{ textTransform: 'capitalize' }}>
                            {title}
                        </div>
                    }
                    description={
                        <>
                            <p>{description || employee.description}</p>
                            {employee ?
                                <Tag color={provinces[employee.province].color}>{employee.province}</Tag> :
                                ads[ad].provinces.map(province => <Tag key={province} color={provinces[province].color}>{province}</Tag>)}
                        </>
                    }
                />
            </List.Item>
        </Badge.Ribbon>
    )
}

export default Document