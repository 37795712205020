import {Map} from 'immutable';
import firebase from '@/lib/firebase'
import _ from 'lodash'
import moment from 'moment';

export function clearUser() {
    localStorage.removeItem('auth');
}

export function getUser() {
    try {
        const auth = firebase.auth().currentUser;
        return {
            uid: auth.uid,
            email: auth.email
        };
    } catch (err) {
        clearUser();
        return new Map();
    }
}

export function arrayEqual(array1, array2) {
    return array1.sort().toString() === array2.sort().toString();
}

export function timeDifference(givenTime) {
    givenTime = new Date(givenTime);
    const milliseconds = new Date().getTime() - givenTime.getTime();
    const number = num => (num > 9 ? '' + num : '0' + num);
    const getTime = () => {
        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            const month = number(givenTime.getUTCMonth() + 1);
            const day = number(givenTime.getUTCDate());
            const year = givenTime.getUTCFullYear() % 100;
            return `${day}-${month}-${year}`;
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            if (days < 28) {
                return `${days} ${days > 1 ? 'dagen' : 'dag'} geleden`;
            } else {
                const months = [
                    'jan',
                    'feb',
                    'mar',
                    'apr',
                    'mei',
                    'jun',
                    'jul',
                    'aug',
                    'sep',
                    'okt',
                    'nov',
                    'dec',
                ];
                const month = months[givenTime.getUTCMonth()];
                const day = number(givenTime.getUTCDate());
                return `${day} ${month}`;
            }
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return `${hours} uur geleden`;
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return `${minutes} ${minutes > 1 ? 'minuten' : 'minuut'} geleden`;
        }
        return 'een paar seconden geleden';
    };
    return getTime();
}

export function stringToInt(value, defValue = 0) {
    if (!value) {
        return 0;
    } else if (!isNaN(value)) {
        return parseInt(value, 10);
    }
    return defValue;
}

export function stringToPosetiveInt(value, defValue = 0) {
    const val = stringToInt(value, defValue);
    return val > -1 ? val : defValue;
}

export function getLatestMessageFromChatroom(x) {
    let latest;
    if (!x.message && (_.isEmpty(x.proposals) || !x.proposals))
        latest = undefined;
    else if (_.isEmpty(x.proposals) && x.message) {
        latest = x.message
    } else {
        let proposal = Object.values(x.proposals).sort((a,b) => moment(b.messageTime) - moment(a.messageTime))[0]
        latest = !x.message ? proposal : moment(proposal.messageTime) > moment(x.message.messageTime) ? proposal : x.message
    }
    return latest
}

export function getRandomKey(length = 20) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
