import React from 'react'
import { Layout, Trigger } from "@/components"
import { Grid } from '@/components'

const { Header } = Layout

export const DashboardHeader = ({ children }) => {
    const screens = Grid.useBreakpoint()

    return (
        <Header style={{ background: '#fff' }}>
            {!screens['md'] && <Trigger />}
            <div style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                {children}
            </div>
        </Header>
    )
}
