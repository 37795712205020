import actions from './actions'

const initState = {
    sending: false,
    error: ''
}

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case actions.SEND_EMAIL:
            return {
                ...state,
                sending: true,
            }
        case actions.SEND_EMAIL_ERROR:
            return {
                ...state,
                sending: false,
                error: payload.error
            }
        case actions.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                sending: false,
                error: ''
            }
        default:
            return state;
    }
}