import styled from 'styled-components';

const ChatRoomListItemWrapper = styled.div`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s ease;
    background: ${props => props.selected ? '#f7f7f7' : 'rgba(0,0,0,0)'};

    .chatRoomListItemGravatar {
        width: 50px;
        margin: 0 15px 0 0;

        img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
        }
    }

    .chatRoomListItemContent {
        width: 100%;
        display: flex;
        flex-direction: column;
    
        h4 {
            font-size: 14px;
            margin: 0;
            font-weight: 500;
        }
    
        .chatExcerpt {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            p {
                margin: 0;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
            }

            .userListsTime {
                font-size: 10px;
                flex-shrink: 0;
                margin-top: auto;
            }
        }
    }
`

export {
    ChatRoomListItemWrapper,
}
