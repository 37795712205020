import logo from "@/assets/images/logo.png";
import { Button, Card, Col, message, Form, Input } from '@/components';
import { FrontPageLayout } from "@/containers/FrontPageLayout";
import useDocumentTitle from "@/lib/helpers/appTitle";
import authActions from '@/store/auth/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from "react-router-dom";

const {
    sendPasswordRecoverMail,
    verifyEmailAddress,
    authFeedback,
} = authActions

export const ResetPasswordPage = () => {
    useDocumentTitle('Koppelbaas - Wachtwoord herstellen')
    const { search } = useLocation()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const params = new URLSearchParams(search)
    const oobCode = params.get('oobCode')
    const { loading, feedback } = useSelector(state => state.Auth)

    useEffect(() => {
        if (oobCode) {
            dispatch(verifyEmailAddress(oobCode))
        }
    }, [dispatch, oobCode])

    useEffect(() => {
        if (feedback) {
            message[feedback.type](feedback.content)
            dispatch(authFeedback())
        }
    }, [dispatch, feedback])

    const handleFinish = async ({ email }) => {
        dispatch(sendPasswordRecoverMail(email))
    }

    return (
        <FrontPageLayout>
            <Col xs={24} sm={20} md={14} lg={10} xl={8} xxl={6}>
                <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <img style={{ maxWidth: '250px' }} src={logo} alt={"koppelbaas-logo"} />
                </div>
                <Card bordered={false}
                    title="Wachtwoord herstellen"
                    style={{ paddingTop: '6px' }}
                    headStyle={{
                        color: '#e7bc38',
                        fontSize: '2em',
                        borderBottom: 'none',
                        paddingBottom: '0',
                        textAlign: 'center',
                        fontWeight: 400,
                    }}>
                    <Form
                        form={form}
                        onFinish={handleFinish}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vul uw e-mailadres in',
                                },
                                {
                                    type: 'email',
                                    message: 'Vul een geldig e-mailadres in',
                                },
                            ]}
                            validateTrigger="onBlur">
                            <Input size="large" placeholder="e-mailadres" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={loading}>
                                Versturen
                            </Button>
                            <div style={{ float: 'right' }}>
                                <Link to="/inloggen">
                                    Terug naar inloggen
                                </Link>
                            </div>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>

        </FrontPageLayout>
    )
}
