const ACTIONS = 'AUTH_'

const actions = {
    AUTH_FEEDBACK: ACTIONS + 'AUTH_FEEDBACK',
    LOGIN: ACTIONS + 'LOGIN',
    LOGIN_SUCCESS: ACTIONS + 'LOGIN_SUCCESS',
    REMOVE_USER: ACTIONS + 'REMOVE_USER',
    SIGN_OUT: ACTIONS + 'SIGN_OUT',
    REGISTER: ACTIONS + 'REGISTER',
    SEND_EMAIL_VERIFICATION: ACTIONS + 'SEND_EMAIL_VERIFICATION',
    SEND_PASSWORD_RECOVER: ACTIONS + 'SEND_PASSWORD_RECOVER',
    VERIFY_EMAIL_ADDRESS: ACTIONS + "VERIFY_EMAIL_ADDRESS",
    VERIFY_EMAIL_ADDRESS_SUCCESS: ACTIONS + "VERIFY_EMAIL_ADDRESS_SUCCESS",

    authFeedback: feedback => ({ type: actions.AUTH_FEEDBACK, payload: { feedback } }),
    login: (email, password) => ({ type: actions.LOGIN, payload: { email, password } }),
    removeUser: () => ({ type: actions.REMOVE_USER }),
    signOut: () => ({ type: actions.SIGN_OUT }),
    register: (addition, city, companyName, email, firstName, houseNumber, kvkNumber, lastName, password, phoneNumber, postalCode, streetName) =>
        ({ type: actions.REGISTER, payload: { addition, city, companyName, email, firstName, houseNumber, kvkNumber, lastName, password, phoneNumber, postalCode, streetName } }),
    sendEmailVerificationMail: () => ({ type: actions.SEND_EMAIL_VERIFICATION }),
    sendPasswordRecoverMail: (email) => ({ type: actions.SEND_PASSWORD_RECOVER, payload: { email } }),
    verifyEmailAddress: oobCode => ({ type: actions.VERIFY_EMAIL_ADDRESS, payload: { oobCode } }),
    verifyEmailAddressSuccess: emailVerified => ({ type: actions.VERIFY_EMAIL_ADDRESS_SUCCESS, payload: { emailVerified } })
}

export default actions
