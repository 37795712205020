import actions from './actions'
import { all, takeEvery, put } from 'redux-saga/effects'
import { db } from '@/lib/firebase'

const collectionPath = 'mail'

function* sendEmail({ payload }) {
    const { data } = payload

    try {
        const ref = yield db.collection(collectionPath).doc()
        yield ref.set(data)
        yield put(actions.sendEmailSuccess())
    } catch (caughtError) {
        console.log(caughtError)
        yield put(actions.sendEmailError({ error: caughtError }))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.SEND_EMAIL, sendEmail),
    ])
}