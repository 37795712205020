import Collection from './Collection'
import FilterTags from './FilterTags'
import React from 'react'

export const HireContent = ({ads}) => {
    return (
        <>
            <FilterTags />
            <Collection ads={ads} />
        </>
    )
}
