import React from 'react'
import _ from 'lodash'
import adsActions from '@/store/ads/actions'
import moment from 'moment'
import provinces from '@/lib/helpers/provinces';
import { Alert, Button, List, Space, Tag } from "@/components";
import { BankOutlined, CalendarOutlined, ProfileOutlined } from "@ant-design/icons";
import { dateFormat } from '@/lib/helpers/date';
import { timeDifference } from "@/lib/helpers/utility";
import { useDispatch, useSelector } from "react-redux";

const {
    setRemoveModalVisible,
    setAdModalVisible,
} = adsActions
const isExpired = ad => 
    moment(ad.period[1], dateFormat).isBefore(moment(moment().format(dateFormat), dateFormat))
const Document = ({ ad }) => {
    const dispatch = useDispatch()
    const { width } = useSelector(state => state.App)
    const { ads } = useSelector(state => state.Ads)
    const { age, createdAt, description, employee, period, title, status } = ads[ad]
    const actions = [
        <Space>
            <CalendarOutlined /><span>Van {period[0]} tot {period[1]}</span>
        </Space>
    ]

    if (employee) {
        actions.push(
            <Space>
                <BankOutlined /><span>€{employee.hourlyRate} per uur</span>
            </Space>
        )
    } else {
        actions.push(
            <Space>
                <ProfileOutlined /><span>Leeftijd tussen {age[0]} en {age[1]} jaar</span>
            </Space>
        )
    }
    return (
        <List.Item
            style={{ flexWrap: 'wrap' }}
            key={ad}
            extra={
                <Space
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        flexWrap: width < 768 ? 'wrap' : 'nowrap'
                    }}>
                    <div>{`${timeDifference(createdAt)} geplaatst`}</div>
                    <div style={{ marginTop: width < 768 ? '16px' : '0' }}>
                        <Button
                            danger
                            disabled={status === 'lended'}
                            style={{ marginRight: '8px' }}
                            onClick={() => dispatch(setRemoveModalVisible(true, ad))}>
                            Verwijderen
                        </Button>
                        <Button
                            disabled={status === 'lended'}
                            onClick={() => dispatch(setAdModalVisible(
                                true,
                                ad,
                                ads[ad].employee ?
                                    getOfferedAdData(ads[ad]) :
                                    getWanteddAdData(ads[ad])
                            ))}>
                            Bewerken
                        </Button>
                    </div>
                </Space >
            }
            actions={actions} >
            <List.Item.Meta
                title={
                    <div style={{ textTransform: 'capitalize' }}>
                        {title}
                    </div>
                }
                description={
                    <>
                        {(isExpired(ads[ad]) || status === 'lended') && <Alert style={{ marginBottom: '10px' }} message={status === 'lended' ? 'Uitgeleend' : "Deze advertentie is verlopen"} type={status === 'lended' ? 'warning' : 'error'} />}
                        <p>{description || employee.description}</p>
                        {employee ?
                            <Tag color={provinces[employee.province].color}>{employee.province}</Tag> :
                            ads[ad].provinces.map(province => <Tag key={province} color={provinces[province].color}>{province}</Tag>)}
                    </>
                }
            />
        </List.Item >
    )
}

export default Document

const getOfferedAdData = ad => ({
    ..._.pick(ad, ['hourlyRate', 'maxTravelDistance']),
    employee: ad.employee.key,
    ..._.pick(ad.employee, ['firstName', 'lastName', 'professions', 'specialty', 'dateOfBirth', 'province', 'description', 'vca', 'hourlyRate']),
    dateOfBirth: moment(ad.employee.dateOfBirth, dateFormat),
    period: ad.period.map(date => moment(date, dateFormat))
})

const getWanteddAdData = ad => ({
    ..._.pick(ad, ['hourlyRate', 'maxTravelDistance'])
})
