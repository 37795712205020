import React from 'react'
import SlidersOutlined from '@ant-design/icons/SlidersOutlined'
import adsActions from '@/store/ads/actions'
import styled from 'styled-components'
import { Grid, Row } from '@/components'
import { DashboardContent } from '@/containers/DashboardLayout/DashboardContent'
import { DashboardHeader } from '@/containers/DashboardLayout/DashboardHeader'
import { DashboardLayout } from '@/containers/DashboardLayout'
import { useDispatch } from 'react-redux'

const {
    toggleFilters
} = adsActions
const HireContent = styled.div`
    display: flex;
    width: 100%;
    background: white;
    flex-grow: 1;
`
export const HireLayout = ({ children }) => {
    const dispatch = useDispatch()
    const screens = Grid.useBreakpoint()

    return (
        <DashboardLayout>
            <DashboardHeader>
                <span className="header-title">Inlenen</span>
                {!screens['md'] && <SlidersOutlined style={{ padding: '21.5px', fontSize: '20px' }} onClick={() => dispatch(toggleFilters())} />}
            </DashboardHeader>
            <DashboardContent>
                <HireContent>
                    <Row style={{ width: '100%' }}>
                        {children}
                    </Row>
                </HireContent>
            </DashboardContent>
        </DashboardLayout>
    )
}
