import actions from "./actions"
import { all, put, takeEvery } from "redux-saga/effects";
import { db } from "@/lib/firebase";
import { getUser } from "@/lib/helpers/utility";

function* getContracts() {
    try {
        const { uid } = getUser()
        const snapshot = yield db.collection('contracts')
            .where(`contractors`, `array-contains`, uid)
            .get()
        yield put(actions.getContractsSuccess(snapshot.docs.map(doc => doc.data())))
    } catch ({ message }) {
        yield put(actions.getContractsError(message))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_CONTRACTS, getContracts)
    ])
}