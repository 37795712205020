import React from 'react';
import { SingleMessage } from "./styles/ChatMessages.styles";
import { timeDifference } from '@/lib/helpers/utility';
import { useAuth } from '@/context/UserProvider';

export const TextMessage = ({ sender, messageTime, text }) => {
    const { uid } = useAuth()
    const isUser = sender.key === uid
    const src = sender.profileImageUrl || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'

    if (isUser) {
        return (
            <SingleMessage className="loggedUser">
                <div className="messageContent isUser">
                    <div className="messageContentText">
                        <p>{text}</p>
                    </div>
                    <div className="messageTime">
                        <p>{timeDifference(messageTime)}</p>
                    </div>
                </div>
                <div className="messageGravatar">
                    <img
                        alt="#"
                        src={src}
                    />
                </div>
            </SingleMessage>
        )
    } else {
        return (
            <SingleMessage>
                <div className="messageGravatar">
                    <img
                        alt="#"
                        src={src}
                    />
                </div>
                <div className="messageContent notUser">
                    <div className="messageContentText">
                        <p>{text}</p>
                    </div>
                    <div className="messageTime">
                        <p>{timeDifference(messageTime)}</p>
                    </div>
                </div>
            </SingleMessage>
        )
    }
}