import _ from 'lodash'
import OfferedForm from './OfferedForm'
import React from 'react'
import WantedForm from './WantedForm'
import adsActions from '@/store/ads/actions'
import { Form, Modal, Radio } from '@/components'
import { dateFormat } from '@/lib/helpers/date'
import { useDispatch, useSelector } from 'react-redux'

const {
    saveAd,
    setAdModalVisible,
    setType
} = adsActions
const AdModal = () => {
    const dispatch = useDispatch()
    const [offeredForm] = Form.useForm()
    const [wantedForm] = Form.useForm()
    const { contractor } = useSelector(state => state.Contractor)
    const { ad, adModalVisible, ads, saving, type } = useSelector(state => state.Ads)

    const handleOk = async () => {
        try {
            let data
            if ((ad ? ads[ad].type : type) === 'offered') {
                await offeredForm.validateFields()
                data = getOfferedObject(offeredForm.getFieldsValue())
            } else {
                await wantedForm.validateFields()
                data = getWantedObject(wantedForm.getFieldsValue())
            }

            dispatch(saveAd(ad, { ...data, type: ad ? ads[ad].type : type, lender: contractor }))
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Modal
            style={{ marignTop: '20px' }}
            forceRender
            centered
            title={ad ?
                "Advertentie bewerken" :
                <Radio.Group onChange={e => dispatch(setType(e.target.value))} value={type}>
                    <Radio value="wanted">
                        Gezocht
                </Radio>
                    <Radio value="offered">
                        Aanbieden
                </Radio>
                </Radio.Group>}
            visible={adModalVisible}
            cancelText="Annuleren"
            okText="Opslaan"
            confirmLoading={saving}
            onCancel={() => dispatch(setAdModalVisible(false))}
            onOk={handleOk}>
            {(ad && ads[ad] && ads[ad].type === 'offered') || type === 'offered' ?
                <OfferedForm form={offeredForm} /> :
                <WantedForm form={wantedForm} />
            }
        </Modal>
    )
}

const getOfferedObject = ad => ({
    ..._.pick(ad, ['title', 'maxTravelDistance', 'period']),
    employee: {
        ..._.pick(ad, ['dateOfBirth','city', 'description', 'employee', 'firstName', 'hourlyRate', 'houseNumber', 'lastName', 'postalCode', 'professions', 'province', 'specialty', 'streetName', 'vca', 'email']),
        key: ad.employee
    },
    period: ad.period.map(date => date.format(dateFormat)),
})

const getWantedObject = ad => ({
    ..._.pick(ad, ['title', 'age', 'description', 'maxTravelDistance', 'period', 'profession', 'provinces']),
    period: ad.period.map(date => date.format(dateFormat)),
})

export default AdModal
