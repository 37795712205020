import bg from "@/assets/images/bg.jpg";
import logo from "@/assets/images/logo.png";
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Layout, message, Row } from '@/components';
import { useAuth } from "@/context/UserProvider";
import useDocumentTitle from "@/lib/helpers/appTitle";
import authActions from '@/store/auth/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";

const { Content } = Layout

const {
    authFeedback,
    register
} = authActions

export const Register = () => {
    useDocumentTitle('Koppelbaas - Registreren')
    const history = useHistory()
    const [form] = Form.useForm()
    const { uid, emailVerified } = useAuth()
    const { loading, feedback } = useSelector(state => state.Auth)
    const dispatch = useDispatch()

    const handleFinish = async values => {
        const { addition, city, companyName, email, firstName, houseNumber, kvkNumber, lastName, password, phoneNumber, postalCode, streetName } = values
        dispatch(register(addition, city, companyName, email, firstName, houseNumber, kvkNumber, lastName, password, phoneNumber, postalCode, streetName))
    }

    useEffect(() => {
        if (uid) {
            if (!emailVerified) {
                history.push('/e-mailadres-bevestigen')
            } else {
                history.push('/inlenen')
            }
        }
    }, [uid, history, emailVerified])

    useEffect(() => {
        if (feedback) {
            message[feedback.type](feedback.content)
            dispatch(authFeedback())
        }
    }, [feedback])

    return (
        <Layout style={{ height: '100vh' }}>
            <Layout>
                <Content style={{ backgroundImage: `url(${bg})` }}>
                    <Row justify="space-around" align="middle" style={{ height: "100%" }}>
                        <Col xs={24} sm={20} md={14} lg={10} xl={10} xxl={8}>
                            <div style={{ textAlign: 'center', padding: '30px 0' }}>
                                <img style={{ maxWidth: '250px' }} src={logo} alt={"koppelbaas-logo"} />
                            </div>
                            <Card title="Registreren" bordered={false}
                                style={{ paddingTop: '6px' }}
                                headStyle={{
                                    color: '#e7bc38',
                                    fontSize: '2em',
                                    borderBottom: 'none',
                                    paddingBottom: '0',
                                    textAlign: 'center',
                                    fontWeight: 400,
                                }}>
                                <Form
                                    form={form}
                                    onFinish={handleFinish}>
                                    <Row gutter={12}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="companyName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vul uw bedrijfsnaam in',
                                                    },
                                                ]}>
                                                <Input size="large" placeholder="bedrijfsnaam" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="kvkNumber"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vul uw KVK-Nummer in',
                                                    },
                                                    {
                                                        len: 8,
                                                        message: 'Vul een geldig KVK-Nummer in'
                                                    },
                                                ]}>
                                                <Input size="large" placeholder="kvk-nummer" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col xs={24} sm={8} md={6}>
                                            <Form.Item
                                                name="postalCode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vul uw postcode in',
                                                    },
                                                ]}>
                                                <Input size="large" placeholder="postcode" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={16} md={18}>
                                            <Form.Item
                                                name="city"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vul uw plaatsnaam in',
                                                    },
                                                ]}>
                                                <Input size="large" placeholder="plaatsnaam" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="streetName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vul uw straatnaam in',
                                                    },
                                                ]}>
                                                <Input size="large" placeholder="straatnaam" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Form.Item
                                                name="houseNumber"
                                                rules={[{ required: true, message: 'Vul uw huisnummer in', },]}>
                                                <InputNumber style={{ width: '100%' }} size="large" placeholder="huisnummer" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6}>
                                            <Form.Item
                                                name="addition">
                                                <Input size="large" placeholder="toevoeging" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name="firstName"
                                                rules={[{ required: true, message: 'Vul uw voornaam in', },]}>
                                                <Input size="large" placeholder="voornaam" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8}>
                                            <Form.Item
                                                name="lastName"
                                                rules={[{ required: true, message: 'Vul uw achternaam in', },]}>
                                                <Input size="large" placeholder="achternaam" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                name="phoneNumber"
                                                rules={[{ required: true, message: 'Vul uw telefoonnummer in', },]}>
                                                <Input size="large" placeholder="telefoonnummer" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    { required: true, message: 'Vul uw e-mailadres in', },
                                                    { type: 'email', message: 'Vul een geldig e-mailadres in', },
                                                ]}>
                                                <Input size="large" placeholder="e-mailadres" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vul een wachtwoord in',
                                                    },
                                                ]}>
                                                <Input.Password size="large" placeholder="wachtwoord" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24}>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                                name="legallyValid"
                                                valuePropName="checked"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject('U moet tekenbevoegd zijn'),
                                                    },
                                                ]}>
                                                <Checkbox>
                                                    Als persoon ben ik tekenbevoegd
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="agreement"
                                                valuePropName="checked"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject('U moet akkoord gaan met de gebruikersovereenkomst'),
                                                    },
                                                ]}>
                                                <Checkbox>
                                                    Ik ga akkoord met de <a
                                                        href="https://storage.googleapis.com/koppelbaas-accept.appspot.com/gebruikersovereenkomst.pdf" 
                                                        target="_blank" 
                                                        rel="noreferrer">gebruikersovereenkomst</a>
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item>
                                                <Button
                                                    size="large"
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={loading}>
                                                    Registeren
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                Heeft u al een account? <Link to="/inloggen">Ga naar inloggen</Link>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    )
}
