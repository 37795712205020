import _ from 'lodash'
import ImgCrop from "antd-img-crop"
import React, { useEffect } from 'react'
import employeesActions from '@/store/employees/actions'
import moment from "moment"
import professions from '@/lib/helpers/professions'
import provinces from '@/lib/helpers/provinces'
import styled from "styled-components"
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Upload, Radio } from "@/components"
import { UploadOutlined } from '@ant-design/icons'
import { dateFormat } from "@/lib/helpers/date"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react";
import { getUser } from '@/lib/helpers/utility'
import { useHistory } from 'react-router-dom'
import Modal from 'antd/lib/modal/Modal'

const { Option } = Select
const { TextArea } = Input
const { downloadURL, removeFeedback, resetFileList, saveEmployee, removeEmployee } = employeesActions

const FormWrapper = styled.div`
    padding: 35px 20px 0;

    .ant-upload-list-picture .ant-upload-list-item {
        border: none;
        padding: 0;
        height: 50px;
        
        .ant-upload-list-item-thumbnail {
            cursor: unset;
        }
    }

    .upload-list-inline .ant-upload-list-item img {
        border-radius: 50%;
    }
`
export const EmployeeForm = ({ employee }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const { feedback, saving } = useSelector(state => state.Employees)
    const [ fileList, setFileList ] = useState([]);
    const { uid } = getUser()
    const history = useHistory()

    useEffect(() => {
        form.resetFields()
        if (employee) {
            form.setFieldsValue(getEmployeeFields(employee))
        }
    }, [employee, form])

    useEffect(() => {
        if (employee && employee.profileImageUrl) {
            dispatch(downloadURL(employee.profileImageUrl))
        } else {
            dispatch(resetFileList())
        }
    }, [dispatch, employee])

    useEffect(() => {
        if (feedback.type && feedback.content) {
            message[feedback.type](feedback.content)
            dispatch(removeFeedback())
        }
    }, [dispatch, feedback])

    const handleFinish = async values => {
        const data = {
            ..._.pick(values, ["city", "description", "firstName", "hourlyRate", "houseNumber", "lastName", "postalCode", "professions", "province", "specialty", "streetName", "vca"]),
            addition: values.addition ? values.addition : "",
            dateOfBirth: values.dateOfBirth.format(dateFormat)
        }

        dispatch(saveEmployee(employee ? employee.id : undefined, data, fileList[0]))
    }

    const handleDelete = (e) => {
        e.preventDefault()
        dispatch(removeEmployee(uid, employee.key))
        history.push('/personeelsbestand')
    }

    return (
        <>
            <FormWrapper>
            {/* <RouteLeavingGuard
            when={true}
            navigate={path => history.push(path)}
            shouldBlockNavigation={() => JSON.stringify(form.getFieldsValue()) !== JSON.stringify(initialValues)}
            okText="Ja"
            cancelText="Nee">
        U heeft niet-opgeslagen wijzigingen. Wilt u uw wijzigen ongedaan maken?
        </RouteLeavingGuard> */}
                <Form
                    form={form}
                    requiredMark={false}
                    colon={false}
                    labelCol={{ xs: 8 }}
                    wrapperCol={{ xs: 16 }}
                    layout="horizontal"
                    labelAlign="left"
                    onFinish={handleFinish}>
                    <Form.Item
                        name="firstName"
                        label="Voornaam"
                        rules={[{ required: true, message: 'Voornaam is verplicht' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label="Achternaam"
                        rules={[{ required: true, message: 'Achternaam is verplicht' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Profielfoto">
                        <ImgCrop modalOk={"Toepassen"} modalCancel={"Annuleren"}>
                            <Upload
                                name="logo"
                                disabled={employee === 'nieuw'}
                                fileList={fileList}
                                showUploadList={{ showRemoveIcon: false }}
                                onPreview={() => { }}
                                maxCount={1}
                                beforeUpload={file => {
                                    setFileList([file]);
                                    return false;
                                }}
                                listType="picture"
                                className="upload-list-inline">
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </ImgCrop>
                    </Form.Item>
                    <Form.Item
                        name="professions"
                        label="Functies"
                        rules={[{ required: true, message: 'Een functie is verplicht' }]}>
                        <Select mode="multiple">
                            {professions.map(profession => <Select.Option key={profession} value={profession}>{profession}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="specialty"
                        label="Specialiteit"
                        rules={[{ required: true, message: 'Een specialiteit is verplicht' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="dateOfBirth"
                        label="Geboortedatum"
                        rules={[{ required: true, message: 'Geboortedatum is verplicht' }]}>
                        <DatePicker format={dateFormat} placeholder="Selecteer" />
                    </Form.Item>
                    <Form.Item
                        name="province"
                        label="Provincie"
                        rules={[{ required: true, message: 'Een provincie is verplicht' }]}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {Object.keys(provinces).map(province => <Option key={province} value={province}>{province}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="postalCode"
                        label="Postcode"
                        rules={[
                            { required: true, message: 'Postcode is verplicht', },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        label="Plaatsnaam"
                        rules={[
                            { required: true, message: 'Plaatsnaam is verplicht', },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="streetName"
                        label="Straatnaam"
                        rules={[
                            { required: true, message: 'Straatnaam is verplicht', },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="houseNumber"
                        label="Huisnummer"
                        rules={[{ required: true, message: 'Huisnummer is verplicht', },]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="addition"
                        label="Toevoeging">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Beschrijving"
                        rules={[{ required: true, message: 'Een bescrhijving is verplicht' }]}>
                        <TextArea allowClear autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                    <Form.Item
                        name="vca"
                        label="VCA"
                        rules={[{ required: true, message: 'VCA is verplicht' }]}>
                        <Radio.Group>
                            <Radio value="VCA-vol">VCA-vol</Radio>
                            <Radio value="VCA-basis">VCA-basis</Radio>
                            <Radio value="geen VCA">geen VCA</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="hourlyRate"
                        label="Uurtarief"
                        rules={[{ required: true, message: 'Een uurtarief is verplicht' }]}>
                        <InputNumber
                            decimalSeparator={","}
                            precision={2}
                            formatter={value => `€ ${value}`}
                            min={0}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ sm: { offset: 8, span: 16 } }}>
                        {employee && <Button
                            style={{ marginRight: 16 }}
                            onClick={(e) => {e.preventDefault();setVisible(true)}}
                            type="danger"
                            htmlType="submit">Verwijderen</Button>}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={saving}>Opslaan</Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
            <Modal
                forceRender
                centered
                title="Selecteer"
                visible={visible}
                onCancel={(e) => setVisible(false)}
                okText="Medewerker verwijderen"
                confirmLoading={false}
                onOk={handleDelete}
                cancelText="Annuleren">
                    Weet je het zeker?
            </Modal>
        </>
    )
}

const getEmployeeFields = employee => ({
    ..._.pick(employee, ["city", "description", "firstName", "hourlyRate", "houseNumber", "lastName", "postalCode", "professions", "province", "specialty", "streetName", "vca"]),
    addition: employee.addition ? employee.addition : "",
    dateOfBirth: moment(employee.dateOfBirth, dateFormat)
})
