import React from 'react'
import styled from 'styled-components';
import { Button, Row } from '@/components'
import { Link } from "react-router-dom";
import { DashboardContent } from '@/containers/DashboardLayout/DashboardContent'
import { DashboardHeader } from '@/containers/DashboardLayout/DashboardHeader'
import { DashboardLayout } from '@/containers/DashboardLayout'

const WorkforceContent = styled.div`
    display: flex;
    width: 100%;
    background: white;

    .workforceRow {
        width: 100%;
    }
`

export const WorkforceLayout = ({ children }) => {
    return (
        <DashboardLayout>
            <DashboardHeader>
                <span className="header-title">Personeelsbestand</span>
                <Button
                    size="large"
                    type="primary"
                    style={{ marginRight: '16px', fontSize: '14px' }}>
                    <Link to="/personeelsbestand/nieuw">Nieuw</Link>
                </Button>
            </DashboardHeader>
            <DashboardContent>
                <WorkforceContent>
                    <Row className="workforceRow">
                        {children}
                    </Row>
                </WorkforceContent>
            </DashboardContent>
        </DashboardLayout>
    )
}
