import { ContractsLayout } from '@/containers/Chat/ContractsLayout'
import ContractsActions from '@/store/contracts/actions'
import EmployeesActions from "@/store/employees/actions"
import React, { useEffect, useState } from 'react'
import { SpinWrapper } from '@/pages/styles/chat.styles'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from '@/components'
import { storage } from '@/lib/firebase'
import { Col, Row, Tabs } from 'antd'
import { getUser } from '@/lib/helpers/utility'
import ContractListItem from './contract-list-item'
import useDocumentTitle from '@/lib/helpers/appTitle'
import moment from 'moment'
import { dateFormat } from '@/lib/helpers/date'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { getContracts } = ContractsActions
const { getEmployees } = EmployeesActions

const ContractsContent = styled.div`
    display: flex;
    flex: 1 0 auto;
    width: 100%;

    .contractsTab .ant-tabs-content-holder {
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .contractsTab .ant-tabs-content-holder::-webkit-scrollbar {
        display: none;
    }

    .hiredTab {
        display: flex;
        flex-direction: column;
        align-self: ${props => props.hirerLength > 0 ? 'start' : 'center'};
    }

    .lendedTab {
        display: flex;
        flex-direction: column;
        align-self: ${props => props.lenderLength > 0 ? 'start' : 'center'};
    }
`

export const ContractsPage = () => {
    useDocumentTitle('Koppelbaas - Contracten')
    const dispatch = useDispatch()
    const { contractsLoaded, contracts } = useSelector(state => state.Contracts)
    const { loaded, employees } = useSelector(state => state.Employees)
    const { uid } = getUser()

    const [hirerContracts, setHirerContracts] = useState([])
    const [lenderContracts, setLenderContracts] = useState([])

    useEffect(() => {
        if (!contractsLoaded) {
            dispatch(getContracts())
        }
        if (!loaded) {
            dispatch(getEmployees())
        }
        if (contractsLoaded && loaded) {
            let lc = []
            let hc = []
            contracts.forEach(doc => {
                storage.ref(`contracts/${doc.pdfLocation}.pdf`).getDownloadURL().then((url) => doc.url = url)
                if (employees[doc.contract.employee.key] && doc.contract.lender.key === uid) {
                    doc.mine = true
                    lc.push(doc)
                } else {
                    doc.mine = false
                    hc.push(doc)
                }
            })
            setLenderContracts(lc.sort((a, b) => moment(a.contract.startDate, dateFormat) - moment(b.contract.startDate, dateFormat)))
            setHirerContracts(hc)
        }
    }, [dispatch, contractsLoaded, loaded, contracts, employees, uid])

    return (
        <ContractsLayout>
            {!(contractsLoaded || loaded) ?
                <SpinWrapper>
                    <Spin />
                </SpinWrapper> :
                <ContractsContent className="content" hirerLength={hirerContracts.length} lenderLength={lenderContracts.length}>
                    <Tabs defaultActiveKey="1" style={{ width: '100%', height: '100%', padding: '0 20px' }} className="contractsTab">
                        <Tabs.TabPane tab={`Ingeleend (${hirerContracts.length})`} key="1" className="hiredTab">
                            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]} type="flex" align="middle">
                                {hirerContracts.length === 0 && (
                                    <Col style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                                            <p style={{ color: 'rgba(0, 0, 0, 0.25)', paddingLeft: 16 }}>
                                                Oeps, het lijkt erop dat je nog geen geaccepteerd inleen contract hebt,
                                                ga naar inlenen en vind jouw vakman
                                            </p>
                                            <Link className='ant-btn ant-btn-primary' to="/inlenen">Vakman inlenen</Link>
                                        </div>
                                    </Col>
                                )}
                                {hirerContracts.map((doc, index) => <ContractListItem key={`hirer-${index}`} doc={doc} />)}
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={`Uitgeleend (${lenderContracts.length})`} key="2" className="lendedTab">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" align="middle">
                                {lenderContracts.length === 0 && (
                                    <Col style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                                            <p style={{ color: 'rgba(0, 0, 0, 0.25)', paddingLeft: 16 }}>
                                                Oeps, het lijkt erop dat je nog geen geaccepteerd uitleen contract hebt,
                                                ga naar inlenen en leen jouw vakman uit
                                            </p>
                                            <Link className='ant-btn ant-btn-primary' to="/inlenen">Gezocht-advertentie zoeken</Link>
                                        </div>
                                    </Col>
                                )}
                                {lenderContracts.map((doc, index) => <ContractListItem key={`lender-${index}`} doc={doc} />)}
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                </ContractsContent>}
        </ContractsLayout>
    )
}