import { all } from 'redux-saga/effects';

import ads from './ads/sagas'
import auth from './auth/sagas'
import chat from './chat/sagas'
import contracts from './contracts/sagas'
import employees from './employees/sagas'
import contractor from './contractor/sagas'
import email from './email/sagas'

export default function* sagas() {
    yield all([
        ads(),
        auth(),
        chat(),
        contracts(),
        employees(),
        contractor(),
        email(),
    ]);
}
