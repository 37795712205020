import React from 'react'
import actions from '@/store/ads/actions'
import { Modal } from '@/components'
import { useDispatch, useSelector } from 'react-redux'

const {
    setRemoveModalVisible,
    removeAd,
} = actions
const RemoveModal = () => {
    const dispatch = useDispatch()
    const { ad, removeModalVisible, removing } = useSelector(state => state.Ads)
    return (
        <Modal
            visible={removeModalVisible}
            closable={false}
            cancelText="Annuleren"
            okText="Ja"
            confirmLoading={removing}
            onCancel={() => dispatch(setRemoveModalVisible(false))}
            onOk={() => dispatch(removeAd(ad))}>
            Weet u zeker dat u deze advertentie wilt verwijderen?
        </Modal>
    )
}

export default RemoveModal