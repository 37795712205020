import styled from 'styled-components';
import { Input as AntdInput } from "antd";

const Input = styled(AntdInput)``;

const WorkforceContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(240, 240, 240);
`

const SpinWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const EmployeeListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const EmployeesSidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: azure;

  .EmployeeListWrapper {
    .messageHelperText {
      background: #fff;
      height: 100%;
      padding: 0 !important;
    }
  }

  .ComposeMessageButton {
    margin-top: auto;
    padding: 20px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    background: #fff;

    button {
      width: 100%;
      margin: 0;
    }
  }
`

const SearchContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(240, 240, 240);

  ${Input} {
    padding: 0;
    border: 0;
    height: 35px;

    &:focus {
      box-shadow: none;
    }
  }
`;

const EmployeeListItem = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease;

  .employeeListItemGravatar {
    width: 50px;
    margin: 0 15px 0 0;
    img {
      border-radius: 50%;
    }
  }
  .employeeListItemContent {
    width: 100%;
    display: flex;

    h4 {
      font-size: 14px;
      margin: 0;
      font-weight: 600;
    }
  }
`;

export {
  EmployeeListContainer,
  EmployeeListItem,
  WorkforceContainer,
  EmployeesSidebar,
  Input,
  SearchContainer,
  SpinWrapper,
}