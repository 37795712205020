import actions from './actions'

const initState = {
    loading: false
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case actions.LOGIN:
            return {
                ...state,
                loading: true,
            }
        case actions.SEND_EMAIL_VERIFICATION:
            return {
                ...state,
                loading: true,
            }
        case actions.SEND_PASSWORD_RECOVER:
            return {
                ...state,
                loading: true,
            }
        case actions.AUTH_FEEDBACK:
            return {
                ...state,
                feedback: payload.feedback,
                loading: false
            }
        case actions.VERIFY_EMAIL_ADDRESS_SUCCESS:
            return {
                ...state,
                emailVerified: payload.emailVerified
            }
        case actions.REGISTER:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}
