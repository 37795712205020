import logo from "@/assets/images/logo.png";
import { Button, Card, Col, message } from '@/components';
import { FrontPageLayout } from "@/containers/FrontPageLayout";
import { useAuth } from "@/context/UserProvider";
import useDocumentTitle from "@/lib/helpers/appTitle";
import authActions from '@/store/auth/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

const {
    signOut,
    sendEmailVerificationMail,
    verifyEmailAddress,
    authFeedback,
} = authActions

export const VerifyEmailAddressPage = () => {
    useDocumentTitle('Koppelbaas - Email verifiëren')
    const { email } = useAuth()
    const { search } = useLocation()
    const dispatch = useDispatch()
    const params = new URLSearchParams(search)
    const oobCode = params.get('oobCode')
    const { loading, feedback } = useSelector(state => state.Auth)

    useEffect(() => {
        if (oobCode) {
            dispatch(verifyEmailAddress(oobCode))
        }
    }, [dispatch, oobCode])

    useEffect(() => {
        if (feedback) {
            message[feedback.type](feedback.content)
            dispatch(authFeedback())
        }
    }, [dispatch, feedback])

    return (
        <FrontPageLayout>
            <Col xs={24} sm={20} md={14} lg={10} xl={8} xxl={6}>
                <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <img style={{ maxWidth: '250px' }} src={logo} alt={"koppelbaas-logo"} />
                </div>
                <Card bordered={false}
                    title="Bijna klaar!"
                    style={{ paddingTop: '6px' }}
                    headStyle={{
                        color: '#e7bc38',
                        fontSize: '2em',
                        borderBottom: 'none',
                        paddingBottom: '0',
                        textAlign: 'center',
                        fontWeight: 400,
                    }}>
                    <p style={{ paddingBottom: '20px' }}>
                        We hebben een email verstuurd naar {email} met daarin een activatielink
                    </p>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        onClick={() => dispatch(sendEmailVerificationMail())}
                        loading={loading}>
                        Opnieuw versturen
                    </Button>
                    <Button
                        style={{ float: 'right', cursor: 'pointer' }}
                        size="large"
                        htmlType="submit"
                        onClick={() => dispatch(signOut())}>
                        Uitloggen
                    </Button>
                </Card>
            </Col>

        </FrontPageLayout>
    )
}
