import styled from 'styled-components'

const OverviewWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background: #fff;
  border: 1px solid rgb(240, 240, 240);  
`

export {
  OverviewWrapper,
}