const ACTIONS = 'CONTRACTS_'

const actions = {
    GET_CONTRACTS: ACTIONS + 'GET_CONTRACTS',
    GET_CONTRACTS_ERROR: ACTIONS + 'GET_CONTRACTS_ERROR',
    GET_CONTRACTS_SUCCESS: ACTIONS + 'GET_CONTRACTS_SUCCESS',

    getContracts: () => ({ type: actions.GET_CONTRACTS }),
    getContractsError: error => ({ type: actions.GET_CONTRACTS_ERROR, payload: { error } }),
    getContractsSuccess: contracts => ({ type: actions.GET_CONTRACTS_SUCCESS, payload: { contracts } }),
}

export default actions