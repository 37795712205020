import { auth, db } from '@/lib/firebase'
import { getAuthErrorContent } from '@/lib/helpers'
import { all, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

function* login({ payload }) {
    try {
        const { email, password } = payload

        yield auth.signInWithEmailAndPassword(email, password)

        yield put(actions.authFeedback({ type: 'success', content: "U bent ingelogd!" }))
    } catch (error) {
        yield put(actions.authFeedback({ type: 'error', content: getAuthErrorContent(error) }))
    }
}

function* signOut() {
    try {
        yield auth.signOut()
    } catch (error) {
        console.log(error.code)
    }
}

function* sendEmailVerification() {
    try {
        const token = yield auth.currentUser.getIdToken()
        const httpHeaders = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let options = {
            method: 'GET',
            headers: new Headers(httpHeaders),
            credentials: 'include',
        };
        const res = yield fetch(`${process.env.REACT_APP_FUNCTIONS_BASE_URL || 'https://us-central1-koppelbaas-5ace4.cloudfunctions.net'}/verifyEmail?email=${encodeURI(auth.currentUser.email)}`, options)
        if (res.status === 200) {
            yield put(actions.authFeedback({ type: 'success', content: 'Bevestigingsmail is opnieuw verstuurd!' }))
        } else {
            yield put(actions.authFeedback({ type: 'error', content: 'Er ging iets mis. Probeer het later nog eens' }))
        }
    } catch (error) {
        yield put(actions.authFeedback({ type: 'error', content: getAuthErrorContent(error) }))
    }
}

function* sendRecoverPassword({ payload }) {
    const { email } = payload
    try {
        let options = {
            method: 'GET',
        };
        const res = yield fetch(`${process.env.REACT_APP_FUNCTIONS_BASE_URL || 'https://us-central1-koppelbaas-5ace4.cloudfunctions.net'}/resetPassword?email=${encodeURI(email)}`, options)
        if (res.status === 200) {
            yield put(actions.authFeedback({ type: 'success', content: 'Wachtwoord herstel email is verstuurd!' }))
        } else {
            yield put(actions.authFeedback({ type: 'error', content: 'Er ging iets mis. Probeer het later nog eens' }))
        }
    } catch (error) {
        yield put(actions.authFeedback({ type: 'error', content: getAuthErrorContent(error) }))
    }
}

function* verifyEmailAddress({ payload }) {
    const { oobCode } = payload

    try {
        yield auth.applyActionCode(oobCode)

        yield put(actions.authFeedback({ type: 'success', content: 'E-mailadres bevestigd!' }))
        yield put(actions.verifyEmailAddressSuccess(true))
    } catch (error) {
        yield put(actions.authFeedback({ type: 'error', content: getAuthErrorContent(error) }))
    }
}

function* register({ payload }) {
    const { email, password, ...rest } = payload

    Object.keys(rest).forEach(key => {
        if (!rest[key]) {
            delete rest[key]
        }
    })

    try {
        const { user: { uid } } = yield auth.createUserWithEmailAndPassword(email, password)

        yield put(actions.authFeedback({ type: 'success', content: 'Succesvol geregistreerd!' }))

        yield db.collection('contractors').doc(uid).set({ key: uid, email, ...rest })

        yield put(actions.sendEmailVerificationMail())

    } catch (error) {
        yield put(actions.authFeedback({ type: 'error', content: getAuthErrorContent(error) }))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOGIN, login),
        takeEvery(actions.SIGN_OUT, signOut),
        takeEvery(actions.REGISTER, register),
        takeEvery(actions.SEND_EMAIL_VERIFICATION, sendEmailVerification),
        takeEvery(actions.SEND_PASSWORD_RECOVER, sendRecoverPassword),
        takeEvery(actions.VERIFY_EMAIL_ADDRESS, verifyEmailAddress),
    ]);
}
